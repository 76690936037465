import React from "react";

import {Config, GlobalProvider} from "./GlobalContext";
import {AuthProvider} from "./AuthContext";
import Layout from "./Layout";
import ErrorBoundary from "./ErrorBoundary";

function App({config}: {config: Config}) {
    return (
        <GlobalProvider config={config}>
            <ErrorBoundary>
                <AuthProvider>
                    <Layout/>
                </AuthProvider>
            </ErrorBoundary>
        </GlobalProvider>
    );
}

export default App;
