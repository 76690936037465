import AWS from "aws-sdk";
import {Body} from "aws-sdk/clients/s3";
import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";
import { uploadFile } from "./uploadFile";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CancelIcon from "@mui/icons-material/Cancel";
import Tooltip from "@material-ui/core/Tooltip";
import {Identity, useAuth} from "../../../App/AuthContext";
import { useGlobalContext } from "../../../App/GlobalContext";
import { getDatalakeRoleCredentials } from "../../../Apis/datalakeRoleCredentials";
import { formatBytes } from "./helpers";
import { Dataset } from "../../../interfaces/dataset";

type NullableUploader = AWS.S3.ManagedUpload | null;
type NullableBody = Body | null;

const useStyles = makeStyles({
    selectSubsetName: {
        width: '100%'
    },
    abortIcon: {
        cursor: "pointer"
    }
});

const normalise = (value: number, max: number) => (value - 0) * 100 / (max - 0);

interface UploadFileDialogProps {
    openState: boolean;
    onCancel: (event?: any) => void;
    dataset: Dataset;
    prefix: string;
}

function UploadFileDialog({ openState, onCancel, dataset, prefix }: UploadFileDialogProps) {
    const classes = useStyles();
    const { identity, authSession } = useAuth();
    const { config, setBackdrop } = useGlobalContext();
    const [fileToUpload, setFileToUpload] = React.useState(null as NullableBody)
    const [isFinished, setFinished] = React.useState(false);
    const [uploadError, setUploadError] = React.useState("");
    const [progress, setProgress] = React.useState(0);
    const [uploadedBytes, setUploadedBytes] = React.useState(0);
    const [totalBytes, setTotalBytes] = React.useState(0);
    const [disableUpload, setDisableUpload] = React.useState(true);
    const [s3Uploader, setS3Uploader] = React.useState(null as NullableUploader);

    const handleCancel = async (event: any) => {
        event.preventDefault();
        await handleAbortUpload();
        onCancel();
    };

    const handleUpload = async (event: any) => {
        if (!setFileToUpload) {
            return;
        }
        try {
            const bucket = `nnedl-core-${config.env}-${dataset.region}-curated`;
            // @ts-ignore
            const key = `${prefix}/${fileToUpload.name}`;
            const bucketRegion = dataset.region as string;
            const creds = getDatalakeRoleCredentials(config, identity as Identity);

            setUploadError("");
            setProgress(0);
            setUploadedBytes(0);
            setTotalBytes(0);
            await uploadFile(
                bucket,
                key,
                bucketRegion,
                // @ts-ignore
                fileToUpload,
                // @ts-ignore
                fileToUpload.type,
                creds,
                handleProgress,
                handleFailedUpload,
                handleUploadStarted);
            console.log('file upload completed');
            setDisableUpload(false);
        } catch (e) {
            console.log(e);
        }
    }

    const handleProgress = (event: any) => {
        const pr = normalise(event.loaded, event.total)
        setProgress(pr);
        setUploadedBytes(event.loaded);
        setTotalBytes(event.total);

        if (pr === 100) {
            setFinished(true);
        }
    }

    const handleUploadStarted = (s3Uploader: AWS.S3.ManagedUpload) => {
        setS3Uploader(s3Uploader);
        setDisableUpload(true);
    }

    const handleFailedUpload = (error: any) => {
        setUploadError(error.toString());
        setDisableUpload(false);
    } 

    const handleChooseFile = (event: any) => {
        const files = event.target.files;
        setProgress(0);
        if (files && files.length > 0) {
            console.log("file chosen: ", event.target.files[0])
            setFileToUpload(files[0]);
            setDisableUpload(false);
        } else {
            setFileToUpload(null);
            setDisableUpload(true);
        }
    }

    const handleEnter = () => {
        setUploadError("");
        setProgress(0);
        setFileToUpload(null);
        setS3Uploader(null);
        setDisableUpload(true);
        setFinished(false);
    }

    const handleAbortUpload = async () => {
        try {
            if (s3Uploader) {
                await s3Uploader.abort();
                console.log('upload aborted');
                return;
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            <Dialog
                open={openState}
                onClose={handleCancel}
                onEnter={handleEnter}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="lg"
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle id="upload-file-dialog-title">
                    {"Upload file to: "}{prefix}
                </DialogTitle>
                {!isFinished && <>
                    <DialogContent>
                        <input type="file" name="choose-file-button" onChange={handleChooseFile} />
                        <Box display="flex" alignItems="center">
                            <Box width="100%" mr={1}>
                                <LinearProgress variant="determinate" value={progress} />
                            </Box>
                            <Box minWidth={35}>
                                <Typography variant="body2" color="textSecondary">{`${Math.round(
                                    progress,
                                )}%`}</Typography>
                            </Box>
                            <Box>
                                <Tooltip title="Abort">
                                    <CancelIcon className={classes.abortIcon} color="error" onClick={handleAbortUpload}></CancelIcon>
                                </Tooltip>
                            </Box>
                        </Box>
                        <Box>
                            <Typography variant="body2" color="textSecondary">{formatBytes(uploadedBytes)} / {formatBytes(totalBytes)}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="body1" color="error">{uploadError}</Typography>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleUpload} id="upload-file-button" color="primary" disabled={disableUpload}>
                            Upload
                        </Button>
                        <Button onClick={handleCancel} id="cancel-upload-button" color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </>}
                {isFinished && <>
                    <DialogContent>
                        File upload finished
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancel} id="cancel-upload-button" color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </>}
            </Dialog>
        </div>
    );
}

export default UploadFileDialog;