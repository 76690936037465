import React, {useEffect} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import {Criticality, ConfidentialityLevel} from './constants'
import {Regions} from '../constants'
import {useAuth} from "../App/AuthContext";
import {useQueryApi} from "../Apis";
import {useGlobalContext} from "../App/GlobalContext";

interface NewDatasetDialogProps {
    openState: boolean;
    onCancel: (value: string) => void;
    onSubmitForm: (dataset: any) => void;
}

function NewDatasetDialog({openState, onCancel, onSubmitForm}: NewDatasetDialogProps) {
    const {identity, authSession} = useAuth();
    // @ts-ignore
    const {config, setBackdrop} = useGlobalContext();
    const queryApi = useQueryApi(config,authSession);
    const [loading, setLoading] = React.useState(false);
    const [hasError, setHasError] = React.useState(true)
    const [managed, setManaged] = React.useState(false);
    const [financial, setFinancial] = React.useState(false);
    const [ehs, setEhs] = React.useState(false);
    const [gxp, setGxP] = React.useState(false);
    const [pii, setPII] = React.useState(false);
    const [name, setName] = React.useState("");
    const [nameError, setNameError] = React.useState(false);
    const [nameErrorText, setNameErrorText] = React.useState("");
    const [confidentiality, setConfidentiality] = React.useState("");
    const [criticality, setCriticality] = React.useState("");
    const [owner, setOwner] = React.useState("");
    const [company, setCompany] = React.useState("");
    const [domain, setDomain] = React.useState("");
    const [clientAccount, setClientAccount] = React.useState("");
    const [clientAccountError, setClientAccountError] = React.useState(false);
    const [clientAccountErrorText, setClientAccountErrorText] = React.useState("");
    const [clientSNSTopicArn, setClientSNSTopicArn] = React.useState("");
    const [clientSNSTopicArnError, setClientSNSTopicArnError] = React.useState(false);
    const [clientSNSTopicArnErrorText, setClientSNSTopicArnErrorText] = React.useState("");
    const [region, setRegion] = React.useState("Init");
    const [costCenter, setCostCenter] = React.useState("");
    const [costCenterError, setCostCenterError] = React.useState(true);
    const [costCenterErrorText, setCostCenterErrorText] = React.useState("");

    useEffect(() => {
        const hasErr = nameError || costCenterError || clientAccountError || clientSNSTopicArnError;
        const hasEmptyFields = name === "" || costCenter === "" || clientAccount === "" || clientSNSTopicArn === "";

        setHasError(hasEmptyFields || hasErr);
    }, [name, nameError, costCenter, costCenterError, clientAccount, clientAccountError, clientSNSTopicArn, clientSNSTopicArnError]);

    const handleOnEnter = () => {
        setLoading(false);
        setManaged( false);
        setFinancial(false);
        setEhs(false);
        setGxP(false);
        setPII(false);
        setName( "");
        setOwner("");
        setCompany("");
        setDomain("");
        setCostCenter("");
        setClientAccount("");
        setClientSNSTopicArn("");
        setRegion("");
        setConfidentiality("");
        setHasError(true);
        setNameError(false);
        setNameErrorText("");
        setCostCenterError(false);
        setClientAccountError(false);
        setClientAccountErrorText("");
        setClientSNSTopicArnError(false);
        setClientSNSTopicArnErrorText("");
    }

    const handleCancel = (event: any) => {
        event.preventDefault();
        onCancel(event.target.value);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setBackdrop(true);

        const dataset = {
            ds_name: name,
            description: name,
            owner,
            region,
            domain,
            cost_center: costCenter,
            company,
            account_id: clientAccount,
            topic_arn: clientSNSTopicArn,
            managed,
            criticality,
            pii,
            gxp,
            env_health_safety: ehs,
            financial,
            confidentiality,
            // @ts-ignore
            steward: identity!.username
        }
        onSubmitForm(dataset);
    };

    const validateName = (event: any) => {
        let regex = null;
        if (managed) {
            regex = new RegExp("^[a-z0-9]{0,11}$")
        } else {
            regex = new RegExp("^[a-z0-9]{0,25}$")
        }
        if (event.target.value.match(regex)) {
            setNameError(false)
            setNameErrorText('')
        } else {
            setNameError(true)
            setNameErrorText('Only lowercase a-z and num 0-9, Max 25 chars. Managed dataset must be max 11 chars')
        }
        setName(event.target.value)
    }

    async function validateClientAccount(event: any) {
        const accountId = event.target.value
        if (! accountId.match(/^\d{12}$/)) {
            setClientAccountError(true)
            // @ts-ignore
            setClientAccountErrorText('Invalid AWS AccountID, Exact 12 numbers')
        } else {
            try {
                const exists = await queryApi.clientAccountExists(accountId);
                if (exists) {
                    setClientAccountError(false);
                    // @ts-ignore
                    setClientAccountErrorText('');
                } else {
                    setClientAccountError(true);
                    // @ts-ignore
                    setClientAccountErrorText('Account does not exist');
                }
            } catch (err) {
                console.error("Call to clientAccountExists failed", err)
            }
        }
        setClientAccount(accountId);
    }

    const validateClientSNSTopicArn = (event: any) => {
        if (! event.target.value.match(/arn:aws:sns:\S+:\d+:\w+/)) {
            setClientSNSTopicArnError(true)
            // @ts-ignore
            setClientSNSTopicArnErrorText("Invalid SNS Arn format")
        } else {
            setClientSNSTopicArnError(false)
            // @ts-ignore
            setClientSNSTopicArnErrorText("")
        }
        setClientSNSTopicArn(event.target.value)
    }

    async function validateCostCenter(event: any) {
        const isValid = await queryApi.validateCostCenter(event.target.value);
        if (isValid) {
            setCostCenterError(false);
            setCostCenterErrorText("");
        } else {
            setCostCenterError(true)
            setCostCenterErrorText("Invalid Cost center")
        }
    }

    return (
        <div>
            <Dialog
                open={openState}
                onClose={handleCancel}
                onEnter={handleOnEnter}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="lg"
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                >
                    <DialogTitle id="form-dialog-title">
                        {"New dataset '" + name + "'" }
                    </DialogTitle>

                    <DialogContent>
                        <Tooltip title="Is this dataset a 'Managed' dataset? (Requires AD Group membership)">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={identity?.steward !== "managed"}
                                        checked={managed ? managed : false}
                                        onChange={(event) => setManaged(event.target.checked)}
                                        name="Managed"
                                        color="primary"
                                    />
                                }
                                label="Managed"
                            />
                        </Tooltip>
                        <Tooltip title="Does this dataset contain 'Financial' data ?">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={financial ? financial : false}
                                        onChange={(event) => setFinancial(event.target.checked)}
                                        name="financial"
                                        color="primary"
                                    />
                                }
                                label="Financial"
                            />
                        </Tooltip>

                        <Tooltip title="Does this dataset contain 'Environmental, safety and health' (ESH) data">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={ehs ? ehs : false}
                                        onChange={(event) => setEhs(event.target.checked)}
                                        name="ehs"
                                        color="primary"
                                    />
                                }
                                label="ESH"
                            />
                        </Tooltip>

                        <Tooltip title="Does this dataset contain 'GxP' data">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={gxp ? gxp : false}
                                        onChange={(event) => setGxP(event.target.checked)}
                                        name="GxP"
                                        color="primary"
                                    />
                                }
                                label="GxP"
                            />
                        </Tooltip>
                        <Tooltip title="Does this dataset contain 'Personally identifiable information' (PII) data">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={pii ? pii : false}
                                        onChange={(event) => setPII(event.target.checked)}
                                        name="PII"
                                        color="primary"
                                    />
                                }
                                label="PII"
                            />
                        </Tooltip>

                        <TextField
                            autoFocus={true}
                            margin="dense"
                            id="name"
                            label="Dataset name"
                            fullWidth={true}
                            value={name}
                            error={nameError}
                            helperText={nameErrorText}
                            onChange={validateName}
                            required={true}
                        />

                        <FormControl fullWidth={true} required={true}>
                            <InputLabel id="confidentiality-label-id">Confidentiality level</InputLabel>
                            <Select
                                labelId="confidentiality-label-id"
                                value={confidentiality}
                                onChange={(event) => {
                                    // @ts-ignore
                                    setConfidentiality(event.target.value);
                                }}
                                autoWidth={false}
                                required={true}
                            >
                                {Object.keys(ConfidentialityLevel).map(key => {
                                    // @ts-ignore
                                    const level = ConfidentialityLevel[key];

                                    return <MenuItem key={key} value={key}>{level}</MenuItem>
                                })}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth={true} required={true}>
                            <InputLabel id="criticality-label-id">Criticality</InputLabel>
                            <Select
                                labelId="criticality-label-id"
                                value={criticality}
                                onChange={(event) => {
                                    // @ts-ignore
                                    setCriticality(event.target.value);
                                }}
                                autoWidth={false}
                                required={true}
                            >
                                {Object.keys(Criticality).map(key => {
                                    // @ts-ignore
                                    const level = Criticality[key];

                                    return <MenuItem key={key} value={key}>{level}</MenuItem>
                                })}
                            </Select>
                        </FormControl>

                        <TextField
                            margin="dense"
                            id="owner"
                            label="Owner"
                            value={owner}
                            fullWidth={true}
                            onChange={(event) => setOwner(event.target.value)}
                            required={true}
                        />
                        <TextField
                            margin="dense"
                            id="company"
                            label="Company"
                            value={company}
                            fullWidth={true}
                            onChange={(event) => setCompany(event.target.value)}
                        />
                        <TextField
                            margin="dense"
                            id="domain"
                            label="Domain"
                            value={domain}
                            fullWidth={true}
                            onChange={(event) => setDomain(event.target.value)}
                        />

                        <TextField
                            margin="dense"
                            id="cost_center"
                            label="Cost Center"
                            value={costCenter ? costCenter : ""}
                            fullWidth={true}
                            onChange={ (event) => setCostCenter(event.target.value)}
                            onBlur={validateCostCenter}
                            error={costCenterError}
                            helperText={costCenterErrorText}
                            required={true}
                        />

                        <FormControl fullWidth={true} required={true}>
                            <InputLabel id="region-label-id">Region</InputLabel>
                            <Select
                                labelId="region-label-id"
                                value={region}
                                onChange={(event) => {
                                    // @ts-ignore
                                    setRegion(event.target.value);
                                }}
                                autoWidth={false}
                                required={true}
                            >
                                {Regions.map(e => (
                                    <MenuItem key={e} value={e}>{e}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            margin="dense"
                            id="clientAccount"
                            label="Client account"
                            error={clientAccountError}
                            helperText={clientAccountErrorText}
                            value={clientAccount}
                            fullWidth={true}
                            onChange={validateClientAccount}
                            required={true}
                        />
                        <TextField
                            margin="dense"
                            error={clientSNSTopicArnError}
                            helperText={clientSNSTopicArnErrorText}
                            id="clientSNSTopicArn"
                            label="SNS Topic ARN"
                            value={clientSNSTopicArn}
                            fullWidth={true}
                            onChange={validateClientSNSTopicArn}
                            required={true}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            color="primary"
                            disabled={hasError}
                        >
                            Save
                        </Button>
                        <Button onClick={handleCancel} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}

export default NewDatasetDialog;
