import {doRequest, generateRequest} from "../helpers";
import {ServerSideCrawlerData} from "../../interfaces/crawler";

export const Queries = {
    GET_USER: "find_user",
    GET_ALL_USERS: 'find_all_users',
    GET_PROFILES: 'find_profiles',
    GET_DATASET: 'find_ds',
    GET_SUBSET: 'find_subset',
    GET_DS_FOLDER: 'list_ds_folder',
    GET_DS_FILES: 'list_ds_files',
    SEARCH_DS_FILES: 'search_ds_files',
    CLIENT_ACCOUNT_EXISTS: 'account_exists',
    GET_DATASET_ATTRIBUTES: 'find_ds_attributes',
    GET_ALL_GROUPS: 'find_all_groups',
    GET_GROUPS: 'find_ds_groups',
    GET_CRAWLERS: 'list_glue_crawler',
    GET_REDSHIFT_LOCATIONS: 'get_redshift_locations'
}

interface QueryFactoryInput {
    config: any;
    authSession: any;
}

export function QueryFactory({config, authSession}: QueryFactoryInput) {
    const baseUrl = `${config.api_base_url}/query`

    async function getProfiles() {
        const url = `${baseUrl}/${Queries.GET_PROFILES}`
        const response = await doRequest(generateRequest(url, "GET"), authSession);

        // validateObject(GetUsersModel, response);
        return response;
    }

    async function getRedshiftLocations() {
        const url = `${baseUrl}/${Queries.GET_REDSHIFT_LOCATIONS}`;
        const response = await doRequest(generateRequest(url, "GET"), authSession);

        return response;
    }

    async function getUser(username: string) {
        const url = `${baseUrl}/${Queries.GET_USER}?user=${username}`
        const response = await doRequest(generateRequest(url, "GET"), authSession);
        // validateObject(GetUsersModel, response);
        return response;
    }

    async function getAllUsers() {
        const url = `${baseUrl}/${Queries.GET_ALL_USERS}`
        const response = await doRequest(generateRequest(url, "GET"), authSession);
        // validateObject(GetUsersModel, response);
        return response;
    }

    async function getDataset(name: string) {
        const url = `${baseUrl}/${Queries.GET_DATASET}?ds_name=${name}`
        const response = await doRequest(generateRequest(url, "GET"), authSession);
        // validateObject(GetUsersModel, response);
        return response;
    }

    async function validateCostCenter(costCenterCode: string) {
        const url = `${config.api_base_url}/costcenter/${costCenterCode}`; // TODO move this to a real Query
        const response = await doRequest(generateRequest(url, "GET"), authSession);
        return response ? response : false;
    }

    async function getDataStructure(datasetName: string) {
        const zone = "curated";
        const url = `${config.api_base_url}/datastructure/${zone}/${datasetName}`; // TODO move this to a Query
        return await doRequest(generateRequest(url, "GET"), authSession);
    }
    async function getDataObjects(datasetName: string, path: string) {
        const zone = "curated";
        const dataUrl = `${config.api_base_url}/data/${zone}/${datasetName}`; // TODO move this to a Query
        const url = path ? `${dataUrl}?path=${path}`: `${dataUrl}`;
        return await doRequest(generateRequest(url, "GET"), authSession);
    }

    async function getGraphToken() {
        const url = `${config.api_base_url}/graphtoken` // TODO move this to a real Query
        return await doRequest(generateRequest(url, "GET"), authSession);
    }
    async function getSubset(datasetName: string, subsetName: string) {
        const url = `${baseUrl}/${Queries.GET_SUBSET}?ds_name=${datasetName}&subset_name=${subsetName}`
        return await doRequest(generateRequest(url, "GET"), authSession);
    }
    async function getDataFolder(datasetName: string, prefix: string) {
        const zone = "curated";
        const url = prefix ? `${baseUrl}/${Queries.GET_DS_FOLDER}?ds_name=${datasetName}&prefix=${prefix}&zone=${zone}` : `${baseUrl}/${Queries.GET_DS_FOLDER}?ds_name=${datasetName}&zone=${zone}`;
        return await doRequest(generateRequest(url, "GET"), authSession);
    }

    async function getDataFiles(datasetName: string, maxKeys: string, prefix: string) {
        const zone = "curated";
        const url = prefix ? `${baseUrl}/${Queries.GET_DS_FILES}?ds_name=${datasetName}&zone=${zone}&max_keys=${maxKeys}&prefix=${prefix}` : `${baseUrl}/${Queries.GET_DS_FILES}?ds_name=${datasetName}&zone=${zone}&max_keys=${maxKeys}`
        return await doRequest(generateRequest(url, "GET"), authSession);
    }

    async function searchDataFiles(datasetName: string, maxKeys: string, prefix: string) {
        const zone = "curated";
        const url = prefix ? `${baseUrl}/${Queries.SEARCH_DS_FILES}?ds_name=${datasetName}&zone=${zone}&max_keys=${maxKeys}&prefix=${prefix}` : `${baseUrl}/${Queries.GET_DS_FILES}?ds_name=${datasetName}&zone=${zone}&max_keys=${maxKeys}`
        return await doRequest(generateRequest(url, "GET"), authSession);
    }

    async function getLifecycleConfiguration(datasetName: string) {
        
        const attributes = "lifecycle";
        const url = `${baseUrl}/${Queries.GET_DATASET_ATTRIBUTES}?ds_name=${datasetName}&attributes=${attributes}`;
        return await doRequest(generateRequest(url, "GET"), authSession);
    }

    async function clientAccountExists(clientAccountId: string) {
        const url = `${baseUrl}/${Queries.CLIENT_ACCOUNT_EXISTS}?account_id=${clientAccountId}`
        const resp = await doRequest(generateRequest(url, "GET"), authSession);
        return resp['exists']
    }

    async function getAllGroups() {
        const url = `${baseUrl}/${Queries.GET_ALL_GROUPS}`
        const response = await doRequest(generateRequest(url, "GET"), authSession);
        return response;
    }

    async function getGroups(name: string) {
        const url = `${baseUrl}/${Queries.GET_GROUPS}?ds_name=${name}`
        const response = await doRequest(generateRequest(url, "GET"), authSession);
        // validateObject(GetUsersModel, response);
        return response;
    }

    async function getCrawlers(name: string, region: string) {
        const url = `${baseUrl}/${Queries.GET_CRAWLERS}?ds_name=${name}&region=${region}`
        const response: ServerSideCrawlerData[] = await doRequest(generateRequest(url, "GET"), authSession);

        return response;
    }

    return {
        getUser,
        getGraphToken,
        getAllUsers,
        getProfiles,
        getDataset,
        validateCostCenter,
        getDataStructure,
        getDataFolder,
        getDataFiles,
        searchDataFiles,
        getDataObjects,
        getSubset,
        getLifecycleConfiguration,
        clientAccountExists,
        getAllGroups,
        getGroups,
        getCrawlers,
        getRedshiftLocations
    }
}