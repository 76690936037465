import React, { useEffect, useState } from 'react';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import FolderIcon from '@mui/icons-material/Folder';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useAuth } from '../../../App/AuthContext';
import { useGlobalContext } from '../../../App/GlobalContext';
import { Dataset } from '../../../interfaces/dataset';
import { useQueryApi } from '../../../Apis';
import { arrangeIntoTree, TreeNode } from './helpers';

const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 400,
        marginTop: 0,
    },
    content: {
        //flexDirection: "row-reverse"
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
    },
}));

interface StyledTreeItemProps {
    labelColor?: string;
    labelIcon?: any;
    labelText: string;
    itemId: string;
    tooltipText?: string;
    children: JSX.Element | null;
}

function StyledTreeItem({
    labelColor,
    labelIcon: LabelIcon,
    labelText,
    itemId,
    tooltipText,
    ...other
}: StyledTreeItemProps) {
    const classes = useTreeItemStyles();
    return (
        <TreeItem
            itemId={itemId}
            label={
                <Tooltip title={tooltipText ? tooltipText : ''}>
                    <div className={classes.labelRoot}>
                        <LabelIcon
                            color={labelColor}
                            className={classes.labelIcon}
                        />
                        <Typography
                            variant="body2"
                            className={classes.labelText}
                        >
                            {labelText}
                        </Typography>
                    </div>
                </Tooltip>
            }
            classes={{
                content: classes.content,
            }}
            {...other}
        />
    );
}

const renderTree = (
    dataset: any,
    structure: any,
    parent: TreeNode[] | null = null,
) => {
    return structure.map((folder: any, i: number) => {
        folder.path = parent ? parent + '/' + folder.name : folder.name;

        return (
            <StyledTreeItem
                itemId={folder.path}
                key={folder.path}
                labelText={folder.name}
                labelIcon={FolderIcon}
            >
                {folder.children.length > 0
                    ? renderTree(dataset, folder.children, folder.path)
                    : null}
            </StyledTreeItem>
        );
    });
};

interface FolderTreeProps {
    dataset: Dataset;
    selectedPath: string;
    onSelect: (itemId: string) => void;
    deletedFolderPath: string;
}

export default function FolderTree({
    dataset,
    selectedPath,
    onSelect,
    deletedFolderPath,
}: FolderTreeProps) {
    const classes = useTreeItemStyles();
    const { authSession, logout } = useAuth();
    const { config } = useGlobalContext();
    const queryApi = useQueryApi(config, authSession);
    const [folderStructure, setFolderStructure] = useState<string[] | undefined>();
    const [selected, setSelected] = useState<string | null>();
    const [expanded, setExpanded]: [
        string[],
        React.Dispatch<React.SetStateAction<string[]>>,
    ] = useState([] as string[]);
    const [deletedFolder, setDeletedFolder] = useState('');

    useEffect(() => {
        handleSelect(null, dataset['name']);
    }, [dataset]);

    useEffect(() => {
        setDeletedFolder(deletedFolderPath);
    }, [deletedFolderPath]);

    useEffect(() => {
        async function callApis() {
            try {
                const prefix = (selected || '').split('/').slice(1).join('/');
                const d = await queryApi.getDataFolder(dataset['name'], prefix);
                const contents = d['Contents'];

                if (contents) {
                    for (var i = 0; i < contents.length; i++) {
                        let pathElements: string = '';

                        if (contents[i].Key) {
                            // TODO: Fix endpoint and remove this statement
                            pathElements = contents[i].Key.replace(
                                /\/$/,
                                '',
                            ).split('/');
                        } else {
                            pathElements = contents[i]
                                .replace(/\/$/, '')
                                .split('/');
                        }

                        if ((folderStructure ?? []).indexOf(pathElements) === -1) {
                            setFolderStructure((oldStructure: string[] | undefined) => [
                                ...(oldStructure ?? []),
                                pathElements,
                            ]);
                        }
                    }
                }
            } catch (err) {
                console.log(err);
            }
            if (deletedFolder) {
                const deletedFolderAsArray = deletedFolder.split('/');

                setFolderStructure(
                    (folderStructure ?? []).filter((folder) =>
                        folder
                            ? folder.toString() !==
                              deletedFolderAsArray.toString()
                            : null,
                    ),
                );
                setDeletedFolder('');
            }
        }

        if (selected) {
            callApis();
        }
    }, [selected]);

    useEffect(() => {
        if (selectedPath != selected) {
            setSelected(selectedPath);
            setExpanded((oldExpanded) => [...oldExpanded, selectedPath]);
        }
    }, [selectedPath]);

    const handleToggle = (event: any, itemIds: string[]) => {
        setExpanded(itemIds);
    };

    const handleSelect = (
        event: React.ChangeEvent<{}> | null,
        itemIds: string | null,
    ) => {
        setSelected(itemIds);
        if (itemIds && !expanded.includes(itemIds)) {
            setExpanded((oldExpanded) => [...oldExpanded, itemIds]);
        }
        onSelect(itemIds || '');
    };

    return (
        <TreeView
            id="data-folder-tree"
            className={classes.root}
            expandedItems={expanded}
            selectedItems={selected}
            defaultSelectedItems={dataset['name']}
            slots={{
                collapseIcon: ArrowDropDownIcon,
                expandIcon: ArrowRightIcon,
            }}
            onExpandedItemsChange={handleToggle}
            onSelectedItemsChange={handleSelect}
        >
            {renderTree(dataset, arrangeIntoTree(folderStructure ?? []))}
        </TreeView>
    );
}
