import React, {useEffect} from "react";
import TransferList from "../../Common/TransferList";

interface DatasetStewardsProps {
    stewards: any[];
    users: {
        username: string;
    }[];
    onAddSteward: (data: any) => void;
    onRemoveSteward: (data: any) => void;
}

function DatasetStewards({stewards, users, onAddSteward, onRemoveSteward}: DatasetStewardsProps) {

    const [stewardData, setStewardData] = React.useState([] as any[]);
    const [otherUsers, setOtherUsers] = React.useState([] as any[]);

    useEffect(() => {
        const filtered = users.filter((user) => {
            return stewards.indexOf(user.username) === -1
        })

        setStewardData(stewards.map((s) => {
            return {username: s}
        }))

        setOtherUsers(filtered)
    }, [users, stewards]);

    return (
        <React.Fragment>
            <TransferList
                columns={[
                    {title: 'User', field: 'username', defaultSort: 'asc'}
                ]}
                leftHeader="All users"
                leftItems={otherUsers}
                rightHeader="Data stewards"
                rightItems={stewardData}
                onLeftChanged={({item: user}) => {
                    onAddSteward(user);
                }}
                onRightChanged={({item: steward}) => {
                    onRemoveSteward(steward);
                }}
                editable={false}
            />
        </React.Fragment>
    );
}

export default DatasetStewards