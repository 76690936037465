import AWS, {Credentials} from "aws-sdk";
import {Body} from "aws-sdk/clients/s3";
import {CredentialsOptions} from "aws-sdk/lib/credentials";

export async function uploadFile(
    bucket: string,
    key: string,
    bucketRegion: string,
    fileContent: Body,
    contentType: string,
    creds: Credentials | CredentialsOptions | null | undefined,
    onProgress: (evt: any) => void,
    onFailed: (evt: any) => void,
    onUploadStarted: (evt: any) => void) {
    try {
        const s3 = new AWS.S3({region: bucketRegion, credentials: creds});
        const s3Uploader = s3.upload({Bucket: bucket, Key: key, Body: fileContent, ContentType: contentType});
        s3Uploader.on('httpUploadProgress', (evt) => {
            onProgress(evt);
        });
        onUploadStarted(s3Uploader);
        await sendAsync(s3Uploader);
    } catch (error) {
        console.log(error);
        onFailed(error);
    }
}

async function sendAsync(s3Uploader: AWS.S3.ManagedUpload) {
    return new Promise((resolve, reject) => {
        s3Uploader.send((err, data) => {
            if(err) {
                return reject(err);
            }
            return resolve(data);
        })
    })
}