import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import {Dataset, Subset} from "../../../interfaces/dataset";

interface CreateSubsetDialogProps {
    openState: boolean;
    onCancel: (value: string) => void;
    onSave: (data: Subset) => void;
    prefix: string;
    dataset: Dataset;
    enablePath: boolean;
}

function CreateSubsetDialog({ openState, onCancel, onSave, prefix, dataset, enablePath }: CreateSubsetDialogProps) {
    const [subsetName, setSubsetName] = React.useState("");
    const [subsetNameError, setSubsetNameError] = React.useState(false);
    const [subsetNameErrorText, setSubsetNameErrorText] = React.useState("");
    const [path, setPath] = React.useState(prefix);
    const [pathError, setPathError] = React.useState(false);
    const [pathErrorText, setPathErrorText] = React.useState("");

    const handleCancel = (event: any) => {
        event.preventDefault();
        onCancel(event.target.value);
    };

    const handleEnter = () => {
        setSubsetName("");
        setSubsetNameError(true);
        setSubsetNameErrorText("Subset name cannot be empty");
        setPath(prefix);
        setPathError(false);
        setPathErrorText("");
        if (enablePath) {
            setPathError(true);
            setPathErrorText("Path cannot be empty");
        }
    }

    const validateSubsetName = (event: any) => {
        if (event.target.value) {
            if(dataset.subsets) {
                const foundSubset = dataset.subsets.find(x => x.name === event.target.value);
                if (foundSubset) {
                    setSubsetNameError(true);
                    setSubsetNameErrorText("Subset name already exists")
                } else {
                    if (event.target.value.match(/^[a-zA-Z0-9\-_]{0,15}$/)) {
                        setSubsetNameError(false)
                        setSubsetNameErrorText("")
                    } else {
                        setSubsetNameError(true)
                        setSubsetNameErrorText('Only lowercase a-z and num 0-9, Max 10 chars')
                    }
                }
            }
            
        } else {
            setSubsetNameError(true);
            setSubsetNameErrorText("Subset name cannot be empty")
        }
        setSubsetName(event.target.value)
    }

    const validatePath = (event: any) => {
        if(event.target.value === "") {
            setPathError(true);
            setPathErrorText("Path cannot be empty");
        } else {
            setPathError(false);
            setPathErrorText("")
        }
        setPath(event.target.value);
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        onSave({subset_name:subsetName, ds_name: dataset.name, path: path, schedule: ""});
    }


    return (
        <div>
            <Dialog
                open={openState}
                onClose={handleCancel}
                onEnter={handleEnter}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="lg"
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                >
                    <DialogTitle id="create-subset-dialog-title">
                        {"Create subset"}
                    </DialogTitle>

                    <DialogContent>
                        
                        <TextField
                            autoFocus={true}
                            margin="dense"
                            id="subset-name-textfield"
                            label="Subset name"
                            fullWidth={true}
                            value={subsetName}
                            error={subsetNameError}
                            helperText={subsetNameErrorText}
                            onChange={validateSubsetName}
                            required={true}
                        />

                        {enablePath?
                        <TextField
                            margin="dense"
                            id="subset-path"
                            label="S3 path"
                            value={path}
                            fullWidth={true}
                            onChange={validatePath}
                            error={pathError}
                            helperText={pathErrorText}
                            required={true}
                        />:<TextField
                        margin="dense"
                        id="subset-path"
                        label="S3 path"
                        value={prefix}
                        fullWidth={true}
                        disabled
                        />}

                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            color="primary"
                            disabled={subsetNameError||pathError}
                        >
                            Save
                        </Button>
                        <Button onClick={handleCancel} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}

export default CreateSubsetDialog;