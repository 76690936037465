import React, {useEffect} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import {useGlobalContext} from "../App/GlobalContext";
import Select from "@material-ui/core/Select";
import { MenuItem } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {Dataset} from "../interfaces/dataset";
import {Crawler} from "../interfaces/crawler";

interface NewCrawlerDialogProps {
    openState: boolean;
    onCancel: (value: string) => void;
    onSubmitForm: (crawler: Crawler) => void;
    dataset: Dataset;
}

function NewCrawlerDialog({openState, onCancel, onSubmitForm, dataset}: NewCrawlerDialogProps) {
    const {config, setBackdrop} = useGlobalContext();
    const [loading, setLoading] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);
    const [schedule, setSchedule] = React.useState("");
    const [scheduleError, setScheduleError] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [scheduleErrorText, setScheduleErrorText] = React.useState("");
    const [subsetName, setSubsetName] = React.useState("");
    const [selectedSubsetName, setSelectedSubsetName] = React.useState("");
    const [crawlerName, setCrawlerName] = React.useState("");
    const [crawlerPath, setCrawlerPath] = React.useState("");

    useEffect(() => {
        const hasErr = scheduleError;
        setHasError(hasErr)
    }, [schedule, scheduleError, subsetName]);

    useEffect(() => {
        setCrawlerName(`${dataset.name}-crawler`);
        setCrawlerPath(dataset.name);
    }, [dataset])

    const handleOnEnter = () => {
        setLoading(false);
        setSchedule("");
        setSubsetName("")
        setSelectedSubsetName("")
        setCrawlerName(`${dataset.name}-crawler`);
        setCrawlerPath(dataset.name);

        setHasError(false);
        setScheduleError(false);
        setScheduleErrorText("");
    }

    const handleCancel = (event: any) => {
        event.preventDefault();
        onCancel(event.target.value);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setIsSubmitting(true);

        setTimeout(() => {
            setBackdrop(true);

            const crawler: any = {
                schedule: schedule,
                ds_name: dataset.name,
                subset_name: selectedSubsetName,
                region: dataset.region,
                path: crawlerPath
            };

            onSubmitForm(crawler);
            setIsSubmitting(false);
        }, 5000);
    };

    const validateSchedule = (event: any) => {
        if (!event.target.value || event.target.value === '') {
            console.log("set schedule to empty");
            setSchedule('');
            setScheduleError(false);
            setScheduleErrorText('');
            return;
        }
        else if(event.target.value.match(/^((\*|\?|\d+((\/|\-){0,1}(\d+))*)\s*){6}$/)) {
            setScheduleError(false);
            setScheduleErrorText('');
        } else  {
            setScheduleError(true);
            setScheduleErrorText('Must match cron pattern');
        }
        setSchedule(event.target.value)
    }

    const onSubsetNameChanged = (event: any) => {
        setSelectedSubsetName(event.target.value);
        const subset = dataset.subsets.find(x => x.name === event.target.value);
        if (event.target.value) {
            setCrawlerName(`${dataset.name}-${event.target.value}-crawler`)
        } else {
            setCrawlerName(`${dataset.name}-crawler`)
        }
        if(subset) {
            setCrawlerPath(subset.path)
        } else {
            setCrawlerPath(dataset.name)
        }
    }

    const renderSubsetItems = () => {
        return dataset.subsets.map(x => {
            return (
                <MenuItem key={x.name} value={x.name}>{x.name}</MenuItem>
            )
        })
    }

    return (
        <div>
            <Dialog
                open={openState}
                onClose={handleCancel}
                onEnter={handleOnEnter}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="lg"
                disableBackdropClick={true}
                disableEscapeKeyDown={true}>
                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}>
                    <DialogTitle id="crawler-form-dialog-title">
                        {"Create new crawler" }
                    </DialogTitle>

                    {!isSubmitting && <>
                    <DialogContent>
                        <TextField
                            margin="dense"
                            id="crawler-name"
                            label="Crawler name"
                            value={crawlerName}
                            fullWidth={true}
                            disabled />

                        <TextField
                            margin="dense"
                            id="crawler-path"
                            label="S3 path"
                            value={crawlerPath}
                            fullWidth={true}
                            disabled />

                        <span><a href="https://docs.aws.amazon.com/glue/latest/dg/monitor-data-warehouse-schedule.html">(look here for further information about schedule expression)</a></span>
                        <TextField
                            autoFocus={true}
                            margin="dense"
                            id="crawler-schedule"
                            label="Schedule"
                            fullWidth={true}
                            value={schedule}
                            error={scheduleError}
                            helperText={scheduleErrorText}
                            onChange={validateSchedule}
                            required={false} />
                        
                        <FormControl fullWidth={true} required={true}>
                            <InputLabel required={false} id="subsetname-label-id">Subset name</InputLabel>
                            <Select
                                labelId="subsetname-label-id"
                                id="subsetname-select"
                                value={selectedSubsetName}
                                autoWidth={false}
                                onChange={onSubsetNameChanged}>
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {renderSubsetItems()}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            color="primary"
                            disabled={hasError}>
                            Save
                        </Button>
                        <Button onClick={handleCancel} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                    </>}
                    {isSubmitting && <DialogContent>
                        Crawler will be created momentarily. If it's not visible immediately, come back to this page in 2 minutes.
                    </DialogContent>}
                </form>
            </Dialog>
        </div>
    );
}

export default NewCrawlerDialog;