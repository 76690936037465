import React, {useEffect} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from '@mui/icons-material/Delete';
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Criticality, ConfidentialityLevel} from '../constants'
import { useAuth } from "../../App/AuthContext";
import { useCommandApi, useQueryApi } from "../../Apis";
import { useGlobalContext } from "../../App/GlobalContext";
import {Dataset} from "../../interfaces/dataset";

interface DeleteDialogProps {
    dataset: Dataset;
    openState: boolean;
    onOk: () => void;
    onCancel: () => void;
}

function DeleteDialog({dataset, openState, onOk, onCancel}: DeleteDialogProps) {
    const [okButtonDisabled, setOkButtonDisabled] = React.useState(true);
    const [datasetDeletionConfirm, setDatasetDeletionConfirm] = React.useState("");
    const [nameError, setNameError] = React.useState(false);
    const [nameErrorText, setNameErrorText] = React.useState("");

    const validate = (event: any) => {
        if (event.target.value !== dataset.name) {
            setNameError(true)
            setNameErrorText('Dataset name does not match')
            setOkButtonDisabled(true)
        } else {
            setNameError(false)
            setNameErrorText('')
            setOkButtonDisabled(false)
        }
        setDatasetDeletionConfirm(event.target.value)
    }

    return (
        <Dialog
            open={openState}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                Are you sure, you want to delete {dataset ? dataset.name : ""}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant="subtitle1">
                        All data will be deleted!
                    </Typography>
                    <Typography variant="subtitle2">
                        Please type the dataset name to confirm
                    </Typography>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="datasetname"
                        label="Dataset name"
                        fullWidth
                        onChange={validate}
                        error={nameError}
                        helperText={nameErrorText}
                        value={datasetDeletionConfirm ? datasetDeletionConfirm : ""}
                        required={true}
                    />
                </DialogContentText>

            </DialogContent>
            <DialogActions>
                <Button disabled={okButtonDisabled} onClick={onOk} color="primary">
                    Ok
                </Button>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

interface DatasetMetadataProps {
    dataset: Dataset;
    onDeleteDataset: (dataset: Dataset) => void;
}

function DatasetMetadata({dataset, onDeleteDataset}: DatasetMetadataProps) {
    const [openDeleteDialogState, setOpenDeleteDialogState] = React.useState(false);

    const handleDeleteDataset = () => {
        onDeleteDataset(dataset);
        setOpenDeleteDialogState(false);
    }

    const handleCancelDeleteDataset = () => {
        setOpenDeleteDialogState(false)
    }

    const { authSession, logout } = useAuth();
    
    const { config, addError, setBackdrop } = useGlobalContext();
    const commandApi = useCommandApi(config, authSession);
    const queryApi = useQueryApi(config,authSession);

    const [financial, setFinancial] = React.useState(false);
    const [ehs, setEhs] = React.useState(false);
    const [gxp, setGxP] = React.useState(false);
    const [pii, setPII] = React.useState(false);
    const [confidentiality, setConfidentiality] = React.useState("");
    const [criticality, setCriticality] = React.useState("");
    const [hasError, setHasError] = React.useState(false);
    const [owner, setOwner] = React.useState("");
    const [company, setCompany] = React.useState("");
    const [domain, setDomain] = React.useState("");
    const [clientAccount, setClientAccount] = React.useState("");
    const [clientAccountHasError, setClientAccountHasError] = React.useState(false);
    const [clientAccountErrorText, setClientAccountErrorText] = React.useState("");
    const [clientSNSTopicArn, setClientSNSTopicArn] = React.useState("");
    const [clientSNSTopicArnHasError, setClientSNSTopicArnHasError] = React.useState(false);
    const [clientSNSTopicArnErrorText, setClientSNSTopicArnErrorText] = React.useState("");
    const [costCenter, setCostCenter] = React.useState("");
    const [costCenterError, setCostCenterError] = React.useState(false);
    const [costCenterErrorText, setCostCenterErrorText] = React.useState("");

    useEffect(() => {
        if(dataset !== null) {
            setFinancial(dataset ? dataset.financial: false);
            setCriticality(dataset ? dataset.criticality : "");
            setConfidentiality(dataset ? dataset.confidentiality: "");
            setEhs(dataset ? dataset.env_health_safety: false);
            setGxP(dataset ? dataset.gxp: false)
            setPII(dataset ? dataset.pii: false)
            setOwner(dataset ? dataset.owner : "");
            setCompany(dataset ? dataset.company : "");
            setDomain( dataset ? dataset.domain: "");
            setClientAccount(dataset ? dataset.accounts[0] : "") // TODO Dette skal laves om til en liste somehow, der er en story på den
            setClientSNSTopicArn(dataset ? dataset.topic_arn : "")
            setCostCenter(dataset ? dataset.cost_center : "")
        }
    }, [dataset])

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setBackdrop(true);
        try {
            const response = await commandApi.updateDataset({
                name: dataset.name,
                cost_center: costCenter,
                topic_arn: clientSNSTopicArn,
                domain: domain,
                description: dataset.description,
                company: company,
                owner: owner,
                criticality: criticality,
                confidentiality: confidentiality,
                pii: pii,
                gxp: gxp,
                financial: financial,
                env_health_safety: ehs
            });
            console.log(response);
            if (response && response.errorMessage) {
                addError(new Error(response.errorMessage));
            }
            setBackdrop(false);
        } catch (error) {
            logout()
        }
    };

    const validateClientAccount = (event: any) => {
        if (event.target.value.match(/^\d{12}$/)) {
            setClientAccountHasError(false)
            setClientAccountErrorText("")
            setHasError(false)
        } else {
            setClientAccountHasError(true)
            setClientAccountErrorText("Invalid AWS AccountID")
            setHasError(true)
        }
        setClientAccount(event.target.value)
    }


    const validateClientSNSTopicArn = (event: any) => {
        if (event.target.value.match(/^arn:aws:sns:\S+:\d+:\w+/)) {
            setClientSNSTopicArnHasError(false)
            setClientSNSTopicArnErrorText("")
            setHasError(false)
        } else {
            setClientSNSTopicArnHasError(true)
            setClientSNSTopicArnErrorText("Invalid SNS Arn format")
            setHasError(true)
        }
        setClientSNSTopicArn(event.target.value)
    }

    async function validateCostCenter(event: any) {
        setCostCenter(event.target.value)
        const isValid = await queryApi.validateCostCenter(event.target.value);
        if (isValid) {
            setCostCenterError(false);
            setCostCenterErrorText("");
            setHasError(false)
        } else {
            setCostCenterError(true)
            setCostCenterErrorText("Invalid Cost center")
            setHasError(true)
        }
    }

    return (
        <div>
            <DeleteDialog dataset={dataset}
                          openState={openDeleteDialogState}
                          onOk={handleDeleteDataset}
                          onCancel={handleCancelDeleteDataset} />

            {!(dataset && dataset.managed) ? (
                <Box textAlign="right">
                    <Button
                        disabled={dataset && dataset.managed}
                        onClick={() => {setOpenDeleteDialogState(true)}}>
                        <DeleteIcon color="secondary" />
                    </Button>
                </Box>
            ): ""}


            <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
            >
                <Tooltip title="Does this dataset contain 'Financial' data ?">
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={false}
                                checked={financial ? financial : false}
                                onChange={(event) => setFinancial(event.target.checked)}
                                name="financial"
                                color="primary"
                            />
                        }
                        label="Financial"
                    />
                </Tooltip>

                <Tooltip title="Does this dataset contain 'Environmental, health and safety' (EHS) data">
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={false}
                                checked={ehs ? ehs : false}
                                onChange={(event) => setEhs(event.target.checked)}
                                name="ehs"
                                color="primary"
                            />
                        }
                        label="EHS"
                    />
                </Tooltip>

                <Tooltip title="Does this dataset contain 'GxP' data">
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={false}
                                checked={gxp ? gxp : false}
                                onChange={(event) => setGxP(event.target.checked)}
                                name="GxP"
                                color="primary"
                            />
                        }
                        label="GxP"
                    />
                </Tooltip>
                <Tooltip title="Does this dataset contain 'Personally identifiable information' (PII) data">
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={false}
                                checked={pii ? pii : false}
                                onChange={(event) => setPII(event.target.checked)}
                                name="PII"
                                color="primary"
                            />
                        }
                        label="PII"
                    />
                </Tooltip>
                <FormControl fullWidth={true} required={true}>
                    <InputLabel id="confidentiality-label-id">Confidentiality level</InputLabel>
                    <Select
                        disabled={false}
                        labelId="confidentiality-label-id"
                        value={confidentiality ? confidentiality : ""}
                        onChange={(event) => setConfidentiality(event.target.value as string)}
                        autoWidth={false}
                        required={true}
                    >
                        {Object.keys(ConfidentialityLevel).map(key => (
                            <MenuItem key={key} value={key}>{ConfidentialityLevel[key as keyof typeof ConfidentialityLevel]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth={true} required={true}>
                    <InputLabel id="criticality-label-id">Criticality</InputLabel>
                    <Select
                        disabled={false}
                        labelId="criticality-label-id"
                        value={criticality ? criticality : ""}
                        onChange={(event) => setCriticality(event.target.value as string)}
                        autoWidth={false}
                        required={true}
                    >
                        {Object.keys(Criticality).map(key => (
                            <MenuItem key={key} value={key}>{Criticality[key as keyof typeof Criticality]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    disabled={false}
                    margin="dense"
                    id="owner"
                    label="Owner"
                    value={owner ? owner : ""}
                    fullWidth={true}
                    onChange={(event) => setOwner(event.target.value)}
                />
                <TextField
                    disabled={false}
                    margin="dense"
                    id="company"
                    label="Company"
                    value={company ? company : ""}
                    fullWidth={true}
                    onChange={(event) => setCompany(event.target.value)}
                />
                <TextField
                    disabled={false}
                    margin="dense"
                    id="domain"
                    label="Domain"
                    value={domain ? domain : ""}
                    fullWidth={true}
                    onChange={(event) => setDomain(event.target.value)}
                />

                <TextField
                    disabled={false}
                    margin="dense"
                    id="cost_center"
                    label="Cost Center"
                    error={costCenterError}
                    helperText={costCenterErrorText}
                    value={costCenter ? costCenter : ""}
                    fullWidth={true}
                    onChange={validateCostCenter}
                    required={true}
                />


                <TextField
                    disabled={true}
                    margin="dense"
                    id="clientAccount"
                    label="Client account"
                    error={clientAccountHasError}
                    helperText={clientAccountErrorText}
                    value={clientAccount ? clientAccount : ""}
                    fullWidth={true}
                    onChange={validateClientAccount}
                />
                <TextField
                    disabled={false}
                    // disabled={clientSNSTopicArnDisabeled}
                    margin="dense"
                    error={clientSNSTopicArnHasError}
                    helperText={clientSNSTopicArnErrorText}
                    id="clientSNSTopicArn"
                    label="SNS Topic ARN"
                    value={clientSNSTopicArn ? clientSNSTopicArn : ""}
                    fullWidth={true}
                    onChange={validateClientSNSTopicArn}
                />

                <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                    <Button
                        type="submit"
                        color="primary"
                        disabled={hasError}
                    >
                        Save
                    </Button>
                    {/* <Button onClick={() => history.push(`/datasets/${dataset.name}`)}>
                        Cancel
                    </Button> */}
                </Grid>
            </form>
        </div>
    );
}

export default DatasetMetadata;