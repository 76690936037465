import React, {useEffect, useState} from "react";
import Title from "../Common/Title";
import MaterialTable from "material-table";
import SearchUsers from "./SearchUsers";
import ComputerIcon from '@mui/icons-material/Computer';
import PersonIcon from '@mui/icons-material/Person';
import Box from "@material-ui/core/Box";
import {useAuth} from "../App/AuthContext";
import {useCommandApi, useQueryApi} from "../Apis";
import {useGlobalContext} from "../App/GlobalContext";

function UserTable() {
    const {graphToken, authSession, logout} = useAuth();

    const {config} = useGlobalContext();

    const queryApi = useQueryApi(config, authSession);
    const commandApi = useCommandApi(config, authSession);

    const [users, setUsers] = useState([]);

    const [columns] = useState([
        {
            title: 'Username',
            field: 'username',
            render: (rowData: any) => {
                if (rowData['type'] === 'machineuser') {
                    return (
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <ComputerIcon/><Box ml={2}>{rowData['username']}</Box>
                        </div>)
                } else {
                    return (
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <PersonIcon/><Box ml={2}>{rowData['username']}</Box>
                        </div>
                    )
                }
            }
        }])


    async function initializeData() {
        try {
            setUsers(await queryApi.getAllUsers());
        } catch (e) {
            logout();
        }
    }

    useEffect(() => {
        initializeData();
    }, []);


    async function handleAddUser(username: string) {
        try {
            await commandApi.createUser(username);
            initializeData();
        } catch (e) {
            logout();
        }
    }

    return (
        <React.Fragment>
            <Box m={2}>
                <Title>
                    Agile users
                </Title>
            </Box>
            <Box m={2}>
                <SearchUsers onDialogOk={handleAddUser} graphToken={graphToken} />
            </Box>
            <Box m={2}>
                <MaterialTable
                    title="NNEDL Users"
                    columns={columns}
                    data={users ? users : []}
                    options={{
                        pageSize: 10,
                        actionsColumnIndex: -1,
                        grouping: false,
                    }}
                />
            </Box>
        </React.Fragment>
    );
}

export default UserTable;