import {useMemo} from "react";
import {CommandFactory} from "./Command";
import {QueryFactory} from "./Query";

export function useCommandApi (config: any, authSession: any) {
    return useMemo(() => CommandFactory({ config, authSession}),
        [authSession, config]);
}

export function useQueryApi (config: any, authSession: any) {
    return useMemo(() => QueryFactory({ config, authSession}),
        [authSession, config]);
}