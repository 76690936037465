import React, {useEffect} from "react";
import MemberTransferList from "./MemberTransferList";

function DatasetMembers({members, users, onAddMember, onRemoveMember, onChangeProfile, profiles}) {

    const [otherUsers, setOtherUsers] = React.useState([])

    useEffect(() => {
        const filtered = users.filter((user) => {
            const dsMemberNames = members.map(x=> x.username)
            return dsMemberNames.indexOf(user.username) === -1
        })
        setOtherUsers(filtered);
    }, [users, members])


    return (
        <React.Fragment>
            <MemberTransferList
                columns={[
                    {title: 'User', field: 'username', defaultSort: 'asc'},
                    {title: 'Email', field: 'username', hidden: true}, // TODO skal mappes op.
                    {title: 'Profile', field: 'profile', hidden: false}
                ]}
                leftHeader="All users"
                leftItems={otherUsers}
                rightHeader="Members"
                rightItems={members}

                onLeftChanged={({item: user, profile}) => {
                    onAddMember(user, profile);
                }}
                onRightChanged={({item: member}) => {
                    onRemoveMember(member);
                }}
                editable={true}
                onChangeProfile={onChangeProfile}
                profiles={profiles}
            />
        </React.Fragment>
    );
};

export default DatasetMembers;
