import { GlobalRegion } from "../constants";
import AWS from "aws-sdk";
import {Identity} from "../App/AuthContext";

export function getDatalakeRoleCredentials(config: any, identity: Identity) {
    const logins: { [key: string]: string } = {};
    const cognitoIdP = `cognito-idp.${GlobalRegion}.amazonaws.com/${config.userPoolId}`;

    logins[cognitoIdP as keyof typeof logins] = identity.idToken;

    const creds = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: config.identityPoolId,
        RoleArn: `arn:aws:iam::${config.accountId}:role/DatalakeRole-${identity.username}`,
        Logins: logins,
        RoleSessionName: "nnedl",
        LoginId: identity.username,
        DurationSeconds: 43200
    }, {
        region: GlobalRegion
    });
    return creds;
}