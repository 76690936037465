import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface ConfirmDialogProps {
  isOpen: boolean;
  handleClosed: () => void;
  handleCancel: () => void;
  message: string;
  title: string;
  id: string;
}

export default function ConfirmDialog({isOpen, handleClosed, handleCancel, message, title, id}: ConfirmDialogProps) {
  const [open, setOpen] = React.useState(isOpen);

  const onClose = () => {
    handleClosed();
  };

  const onCancel = () => {
    handleCancel();
  };

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen]);

  return (
    <div>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={onClose}
        aria-labelledby={id+"-title"}
        aria-describedby={id+"-description"}
      >
        <DialogTitle id={id+"-title"}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id={id+"-description"}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" autoFocus>
            Ok
          </Button>
          <Button onClick={onCancel} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
