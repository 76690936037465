import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App/App';
import * as serviceWorker from './serviceWorker';
import { Amplify, Auth } from 'aws-amplify';
import config from './config.json';

let baseUrl = window.location.hostname;

const loadConfig = async () => {
    if (process.env.REACT_APP_BASE_URL) {
        baseUrl = process.env.REACT_APP_BASE_URL;
    }

    const bootstrapEndpoint = `https://api.${baseUrl}/bootstrap`;
    const response = await fetch(bootstrapEndpoint);

    if (response && response.ok && response.status === 200) {
        const data = await response.json();

        applyConfig(data);
    } else {
        throw new Error('Failed to get config');
    }
};

// @ts-ignore
const applyConfig = (data) => {
    config.env = data.env;
    config.app_url = baseUrl;
    config.api_base_url = `https://api.${baseUrl}`;
    config.identityPoolId = data.identityPoolId;
    config.userPoolId = data.userPoolId;
    config.userPoolWebClientId = data.userPoolWebClientId;
    config.cognitoDomain = data.cognitoDomain;
    config.redirectSignIn = process.env.REACT_APP_REDIRECT_SIGNIN
        ? process.env.REACT_APP_REDIRECT_SIGNIN
        : data.redirectSignIn;
    config.redirectSignOut = process.env.REACT_APP_REDIRECT_SIGNOUT
        ? process.env.REACT_APP_REDIRECT_SIGNOUT
        : data.redirectSignOut;
    config.accountId = data.accountId;
    config.featureSwitches = data.featureSwitches;
};

const start = async () => {
    try {
        await loadConfig();

        Amplify.configure({
            Auth: {
                identityPoolId: config.identityPoolId,
                region: 'eu-central-1',
                identityPoolRegion: 'eu-central-1',
                userPoolId: config.userPoolId,
                userPoolWebClientId: config.userPoolWebClientId,
                mandatorySignIn: true,
                oauth: {
                    domain: config.cognitoDomain,
                    scope: ['email', 'openid'],
                    redirectSignIn: config.redirectSignIn,
                    redirectSignOut: config.redirectSignOut,
                    responseType: 'token',
                },
            },
        });
        Auth.configure();

        const container = document.getElementById('root');
        const root = createRoot(container!);
        root.render(<App config={config} />);

        serviceWorker.unregister();
    } catch (error) {
        const container = document.getElementById('root');
        const root = createRoot(container!);
        root.render(<div>Error loading site</div>);
    }
};

start();
