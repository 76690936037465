import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core";
import ChevronRight from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ConfirmDialog from '../../Common/ConfirmDialog';
import ProfileDialog from '../../Common/ProfileDialog';
import {Profile} from "../../interfaces/profile";

const removeMemberMessage = "User will be removed from your dataset"
const removeMemberTitle = "Remove user"
const addMemberMessage = "A user profile determines the individual profile given for a user by the steward. The effective profile of a user is a combination of the individual user profile and the group profile (if the user is member of a group)."
const addMemberTitle = "Add as member to dataset"

const useStyles = makeStyles((theme) => ({
    displayNone: {
        display: 'none'
    },
    profileTitle: {
        fontSize: 14
    }
}));

const permissionTable = {
    "r": "read",
    "w": "write",
    "n": "none"
};

interface TransferListProps {
    leftHeader: any;
    rightHeader: any;
    columns: any;
    leftItems: any;
    rightItems: any;
    onLeftChanged: (arg: any) => void;
    onRightChanged: (arg: any) => void;
    editable: boolean;
    onChangeProfile: () => void;
    profiles: Profile[];
}

function MemberTransferList(
    {
        leftHeader,
        rightHeader,
        columns,
        leftItems,
        rightItems,
        onLeftChanged,
        onRightChanged,
        editable,
        onChangeProfile,
        profiles
    }: TransferListProps) {
    const [left, setLeft] = React.useState(leftItems);
    const [right, setRight] = React.useState(rightItems);
    const [showRemoveMemberDialog, setShowRemoveMemberDialog] = React.useState(false)
    const [showProfileDialog, setShowProfileDialog] = React.useState(false)
    const [memberToAdd, setMemberToAdd] = React.useState(null);
    const [memberToRemove, setMemberToRemove] = React.useState(null);
    const rightRef = React.useRef(right);
    const leftRef = React.useRef(left);

    const getPermission = (profileName: string) => {
        if (profiles) {
            const profile = profiles.find(x => x.name === profileName);
            if (profile) {
                return {
                    source: permissionTable[profile.permissions!.charAt(0) as keyof typeof permissionTable],
                    raw: permissionTable[profile.permissions!.charAt(1) as keyof typeof permissionTable],
                    transient: permissionTable[profile.permissions!.charAt(2) as keyof typeof permissionTable],
                    curated: permissionTable[profile.permissions!.charAt(3) as keyof typeof permissionTable]
                };
            }
        }
        return null;
    }
    const displayPermissions = (profile: string) => {
        const p = getPermission(profile);
        
        return (
            <ul>
                <li>Source: {p?p.source:''}</li>
                <li>Raw: {p?p.raw:''}</li>
                <li>Transient: {p?p.transient:''}</li>
                <li>Curated: {p?p.curated:''}</li>
            </ul>
        );
    }

    const _setRight = (data: any) => {
        rightRef.current = data;
        setRight(data);
    };
    const _setLeft = (data: any) => {
        leftRef.current = data;
        setLeft(data);
    };

    useEffect(() => {
        _setLeft(leftItems)
        _setRight(rightItems)
    }, [leftItems, rightItems]);

    const handleToRight = (event: any, item: any) => {
        event.preventDefault();
        setShowProfileDialog(true);
        setMemberToAdd(item);
    }

    const handleToLeft = (event: any, item: any) => {
        event.preventDefault();
        setShowRemoveMemberDialog(true);
        setMemberToRemove(item);
    }

    const handleChangeProfile = async (event: any, item: any) => {
        const newRight = rightRef.current.map((x: any) => {
            if(x.username === item.username) {
                const updatedItem = {
                    ...x,
                    profile: event.target.value
                };
                return updatedItem;
            }
            return x;
        })
        _setRight(newRight);
        // @ts-ignore
        await onChangeProfile({username: item.username, profile: event.target.value});
    }

    const closeRemoveMemberDialog = () => {
        setShowRemoveMemberDialog(false);
        console.log("member to remove:", memberToRemove);
        // @ts-ignore
        const r = right.filter((m: any) => m.tableData.id !== memberToRemove.tableData.id);
        const l = [...left, memberToRemove];
        _setRight(r);
        _setLeft(l);
        onRightChanged({item: memberToRemove, left, right});
    }

    const closeProfileDialog = (profile: string) => {
        setShowProfileDialog(false);
        console.log("profile chosen: ", profile);
        // @ts-ignore
        const l = left.filter((m: any) => m.tableData.id !== memberToAdd.tableData.id);
        const r = [...right, memberToAdd];
        _setRight(r);
        _setLeft(l);
        onLeftChanged({item: memberToAdd, left, right, profile});
    }

    const cancelProfileDialog = () => {
        setShowProfileDialog(false);
    }

    const cancelRemoveMemberDialog = () => {
        setShowRemoveMemberDialog(false);
    }

    const classes = useStyles();

    return (
        <Grid container component="span" spacing={2}>
            <ConfirmDialog id="remove-member-dialog" title={removeMemberTitle} message={removeMemberMessage} isOpen={showRemoveMemberDialog} handleClosed={closeRemoveMemberDialog} handleCancel={cancelRemoveMemberDialog}></ConfirmDialog>
            <ProfileDialog id="member-profile-dialog" title={addMemberTitle} message={addMemberMessage} isOpen={showProfileDialog} handleClosed={closeProfileDialog} handleCancel={cancelProfileDialog} profiles={profiles}></ProfileDialog>
            <Grid container component="span" spacing={5}>
                <Grid component="span" item xs={6}>
                    <MaterialTable
                        title={leftHeader}
                        columns={columns}
                        data={left}
                        actions={[
                            {
                                icon: 'addbox',
                                tooltip: 'Add',
                                onClick: handleToRight,
                            }
                        ]}
                        options={{
                            grouping: false,
                            sorting: true,
                            actionsColumnIndex: -1,
                            // @ts-ignore
                            cellStyle: {width: "85%"},
                            actionsCellStyle: {width: "15%"}
                        }}
                    />
                </Grid>
                <Grid component="span" item xs={6}>
                    <MaterialTable
                        detailPanel={[{
                            render: rowData => {
                                return ( 
                                    <Card>
                                        <CardContent>
                                        <Typography className={classes.profileTitle} color="textSecondary" gutterBottom>
                                            To change the profile for the current user select one of the profiles in the dropdown. The permission for a profile defines the access level for each dataset zones.
                                        </Typography>
                                        <div>
                                            <Select
                                            value={rowData.profile}
                                            onChange={(event) => handleChangeProfile(event, {profile:rowData.profile, username:rowData.username})}>
                                                {profiles.map(x => (
                                                    <MenuItem key={x.name} value={x.name}>{x.name}</MenuItem>
                                                ))};
                                            </Select>
                                        </div>
                                        {displayPermissions(rowData.profile)}
                                </CardContent>
                                </Card>
                                )},
                            disabled: !editable,
                            icon: () => <ChevronRight className={!editable?classes.displayNone:''} />,
                            openIcon: ExpandMore
                        }]}
                        title={rightHeader}
                        columns={columns}
                        data={rightRef.current}
                        actions={[
                            {
                                icon: 'remove',
                                tooltip: 'Remove user',
                                onClick: handleToLeft
                            }
                        ]}
                        options={{
                            grouping: false,
                            sorting: true,
                            actionsColumnIndex: -1,
                            // @ts-ignore
                            cellStyle: {width: "85%"},
                            actionsCellStyle: {width: "15%"}
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
export default MemberTransferList;