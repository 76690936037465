import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    },
}));

export default function CircularProgressWrapper() {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <CircularProgress />
        </div>
    );
}
