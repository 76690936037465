import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {useAuth} from "../AuthContext";
import Avatar from "@material-ui/core/Avatar";
import {useGlobalContext} from "../GlobalContext";

export default function UserProfile() {
    const {config} = useGlobalContext();
    const {logout} = useAuth()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [username, setUsername] = React.useState("");
    const {authState, identity} = useAuth();

    const navigate = useNavigate();
    const open = Boolean(anchorEl);

    useEffect(() => {
        setUsername(identity ? identity.username: "")
    }, [identity]);

    const handleOpenMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    async function handleLogOut() {
        logout()
        handleClose()
    }

    // @ts-ignore
    const avatar = <Avatar
        alt={username.toUpperCase()}
        src="#"
        disabled={(authState !== "signedIn")}
    />;

    return (
        <React.Fragment>
            <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenMenu}
                color="inherit"
                disabled={(authState !== "signedIn")}>
                {avatar}
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={ () => {
                    handleClose()
                    navigate("/credentials");
                }}>{username}
                </MenuItem>

                <MenuItem
                    onClick={handleLogOut}
                >Log out
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}