import React, {useEffect} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import {useAuth} from "../App/AuthContext";
import {useQueryApi} from "../Apis";
import {useGlobalContext} from "../App/GlobalContext";
import {Crawler} from "../interfaces/crawler";

interface EditCrawlerDialogProps {
    openState: boolean;
    onCancel: (string: any) => void;
    onSubmitForm: (crawler: Crawler) => Promise<void>;
    crawler: Crawler | null;
}

function EditCrawlerDialog({openState, onCancel, onSubmitForm, crawler}: EditCrawlerDialogProps) {
    const {identity, authSession} = useAuth();
    const {config, setBackdrop} = useGlobalContext();
    const queryApi = useQueryApi(config,authSession);
    const [loading, setLoading] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [schedule, setSchedule] = React.useState(crawler?crawler.schedule:"");
    const [scheduleError, setScheduleError] = React.useState(false);
    const [scheduleErrorText, setScheduleErrorText] = React.useState("");

    useEffect(() => {
        const hasErr = scheduleError;
        setHasError(hasErr)
    }, [schedule, scheduleError]);

    useEffect(() => {
        if (crawler && crawler.schedule) {
            setSchedule(crawler.schedule);
        }
    }, [crawler]);


    const handleOnEnter = () => {
        setLoading(false);
        //setSchedule("");

        setHasError(false);
        setScheduleError(false);
        setScheduleErrorText("");
    }

    const handleCancel = (event: any) => {
        event.preventDefault();
        onCancel(event.target.value);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setIsSubmitting(true);

        setTimeout(() => {
            setBackdrop(true);

            const data = {
                schedule: schedule,
                name: crawler!.name
            } as Crawler;

            onSubmitForm(data);
            setIsSubmitting(false);
        }, 5000);
    };


    const validateSchedule = (event: any) => {
        console.log("schedule: ", event.target.value);
        if (!event.target.value || event.target.value === '') {
            console.log("set schedule to empty");
            setSchedule('');
            setScheduleError(false);
            setScheduleErrorText('');
            return;
        }
        else if(event.target.value.match(/^((\*|\?|\d+((\/|\-){0,1}(\d+))*)\s*){6}$/)) {
            setScheduleError(false);
            setScheduleErrorText('');
        } else  {
            setScheduleError(true);
            setScheduleErrorText('Must match cron pattern');
        }
        setSchedule(event.target.value)
    }
    
    return (
        <div>
            <Dialog
                open={openState}
                onClose={handleCancel}
                onEnter={handleOnEnter}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="lg"
                disableBackdropClick={true}
                disableEscapeKeyDown={true}>
                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}>
                    <DialogTitle id="edit-crawler-form-dialog-title">
                        {crawler?crawler.name:"Edit Crawler" }
                    </DialogTitle>

                    {!isSubmitting && <>
                    <DialogContent>
                        
                    <span><a href="https://docs.aws.amazon.com/glue/latest/dg/monitor-data-warehouse-schedule.html">(look here for further information about schedule expression)</a></span>
                        <TextField
                            autoFocus={true}
                            margin="dense"
                            id="edit-crawler-schedule"
                            label="Schedule"
                            fullWidth={true}
                            value={schedule}
                            error={scheduleError}
                            helperText={scheduleErrorText}
                            onChange={validateSchedule}
                            required={false} />

                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            color="primary"
                            disabled={hasError}>
                            Save
                        </Button>
                        <Button onClick={handleCancel} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                    </>}
                    {isSubmitting && <DialogContent>
                        Crawler will be created momentarily. If it's not visible immediately, come back to this page in 2 minutes.
                    </DialogContent>}
                </form>
            </Dialog>
        </div>
    );
}

export default EditCrawlerDialog;