import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {useCommandApi, useQueryApi} from "../../../Apis";
import {useAuth} from "../../../App/AuthContext";
import {useGlobalContext} from "../../../App/GlobalContext";
import {makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";

import CircularProgress from "@material-ui/core/CircularProgress";
import { IconButton, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import SubsetMenuButton from "./SubsetMenuButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import CreateSubsetDialog from "./CreateSubsetDialog";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import {Dataset} from "../../../interfaces/dataset";
import {Column} from "../../../interfaces/column";


const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        marginBottom: 33
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "row-reverse"
    },
    addButton: {
        margin: theme.spacing(1),
    },
    tableContainer: {
        marginTop: 43,
        maxHeight: 440,
    },
    spinner: {
      marginTop: 43
    },
    filesEmptyText: {
      color: "grey",
      fontSize: 18,
      textAlign: "center",
      marginTop: 24
    }
}));

const columns: Column[] = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'path', label: 'Path', minWidth: 170 },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 100,
      align: 'right',
    }    
  ];

interface TableRow {
    name?: string;
    path: string;
}

const mapSubsetsToTable = (dataset: Dataset) => {
    let tableRows: TableRow[] = [];

    if (dataset && dataset.subsets && dataset.subsets.length > 0) {
        dataset.subsets.map((subset) => {
            tableRows.push({
                name: subset.name,
                path: subset.path
            })
        });
    }
    
    return tableRows;
}

interface SubsetTableProps {
    dataset: Dataset;
    onDeleteSubset: (datasetName: string, subsetName: string) => void;
}

function SubsetTable({dataset, onDeleteSubset}: SubsetTableProps) {
    const classes = useStyles();
    const rows = mapSubsetsToTable(dataset)

    const {config} = useGlobalContext();
    const { identity, authSession } = useAuth();

    const handleDeleteSubset = async (subsetName: string) => {
      await onDeleteSubset(dataset.name, subsetName);
    }

    return (
      <div>
        <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, borderBottom: "1px solid black" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
          {rows.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.name}>
                  {columns.map((column) => {
                    const value = row[column.id as keyof typeof row];
                    if(column.id === 'actions') 
                    return (
                        <TableCell key="actions" align="right"> 
                            <SubsetMenuButton subsetName={row.name!} onDeleteSubset={handleDeleteSubset}/>
                        </TableCell>
                    );
                    else 
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                  
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {rows.length === 0 &&
        <div className={classes.filesEmptyText}>No subsets</div>
      }
      </div>
    )
}

interface SubsetViewProps {
    datasetName: string;
}

interface Subset {
    ds_name: string;
    subset_name: string;
}

type NullableSubset = Subset | null;
type NullableDataset = Dataset | null;

export default function SubsetView({datasetName}: SubsetViewProps) {
    const classes = useStyles();
    const {authSession} = useAuth();
    const {config, addError} = useGlobalContext();
    const queryApi = useQueryApi(config,authSession);
    const commandApi = useCommandApi(config, authSession);
    const [loading, setLoading] = useState(false);
    const [showInfo, setShowInfo] = useState(true);
    const [dataset, setDataset]: [NullableDataset, React.Dispatch<React.SetStateAction<NullableDataset>>] = useState(null as NullableDataset);
    const [showCreateSubsetDialog, setShowCreateSubsetDialog] = useState(false);
    const [subsetToDelete, setSubsetToDelete]: [NullableSubset, React.Dispatch<React.SetStateAction<NullableSubset>>] = useState(null as NullableSubset);
    const [showDeleteSubsetDialog, setShowDeleteSubsetDialog] = React.useState(false)

    async function reloadSubsets() {
      setLoading(true);
      try {
          console.log("get dataset name: ", datasetName);
          const loadedDataset = await queryApi.getDataset(datasetName);
          setDataset(loadedDataset);
          setLoading(false);
      } catch (err) {
          console.log(err);
          setLoading(false);
          addError(new Error(err?.toString()));
      }
    }

    useEffect(() => {
      reloadSubsets();
    }, []);  

    const handleDismissInfo = () => {
      setShowInfo(false);
    }

    const handleDeleteSubset = async (datasetName: string, subsetName: string) => {
      setSubsetToDelete({ds_name: datasetName, subset_name: subsetName});
      setShowDeleteSubsetDialog(true);
    }

    const renderInfo = () => {
      if (loading) return;
      if (showInfo) {
        return (
          <Card variant="outlined">
                    <CardContent>
                    <Typography>A subset has two properties a <b>name</b> and a <b>path</b>.</Typography>
                    <Typography>You cannot have multiple subsets with the same name but you can have multiple subsets with the same path.</Typography>
                    <Typography>The path of a subset specifies the S3 path in your dataset. A crawler can be created to collect data specified by a subset's path property.</Typography>
                    <Typography>When a subset is created a Redshift local and external schema will be created for that subset.</Typography>
                    <br></br>
                    <Typography><b>Notice:</b> deleting a subset will not affect any data in the dataset, only the Redshift schema will be deleted.</Typography>
                    <Typography>This operation could take a few minutes to complete, so refresh the page to check for changes.</Typography>
                    </CardContent>
                    <CardActions>
                      <Button variant="outlined" size="small" onClick={handleDismissInfo}>Dismiss</Button>
                    </CardActions>
                  </Card>
        )
      }
    }

    const handleRefreshSubsets = async () => {
      await reloadSubsets();
    }

    const handleCreateSubsetButtonClick = async () => {
      setShowCreateSubsetDialog(true);
    }

    const handleCancelCreateSubset = (event: any) => {
      setShowCreateSubsetDialog(false);
    }

    const handleCreateSubset = (subset: Subset) => {
      console.log("create subset: ", subset);
      setShowCreateSubsetDialog(false);
      try {
        //setBackdrop(true);
        //await commandApi.createSubset(subset.ds_name, subset.subset_name, subset.path, subset.schedule);
      } catch (e) {
          console.log(e);
          addError(new Error(e?.toString()));
      }
      finally {
        //setBackdrop(false);
      }
    }

    const closeDeleteSubsetDialog = async () => {
      setShowDeleteSubsetDialog(false);
      try {
        console.log("delete subset: ",subsetToDelete);
        setLoading(true);
        await commandApi.deleteSubset(subsetToDelete!.ds_name, subsetToDelete!.subset_name);
      } catch (error) {
        console.log(error);
        addError(new Error(error?.toString()));
      } finally {
        await reloadSubsets();
      }
    }

    const cancelDeleteSubsetDialog = () => {
      setShowDeleteSubsetDialog(false);
    }

    return (
        <div>
            <CreateSubsetDialog
              openState={showCreateSubsetDialog}
              onCancel={handleCancelCreateSubset}
              onSave={handleCreateSubset}
              prefix={""}
              dataset={dataset as Dataset}
              enablePath={true}
            ></CreateSubsetDialog>
            <ConfirmDialog id="delete-subset-dialog" title={"Delete subset?"} message={"Are you sure you want to delete the subset?"} isOpen={showDeleteSubsetDialog} handleClosed={closeDeleteSubsetDialog} handleCancel={cancelDeleteSubsetDialog}></ConfirmDialog>
            <Grid container spacing={3}>
                <Grid item xs={12} className={classes.buttonContainer}>
                
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                  {renderInfo()}
                </Grid>
                <Grid item xs={12} className={classes.buttonContainer}>
                  <Tooltip title="Refresh">
                    <IconButton color="default" aria-label="Refresh subsets" onClick={handleRefreshSubsets}>
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Create new subset">
                    <IconButton color="default" aria-label="Create new subset" onClick={handleCreateSubsetButtonClick}>
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
            </Grid>

            {loading ? <CircularProgress className={classes.spinner} /> : <SubsetTable dataset={dataset as Dataset} onDeleteSubset={handleDeleteSubset}/>}
        </div>
    )
}
