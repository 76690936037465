import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { useAuth } from "../App/AuthContext";
import { useCommandApi, useQueryApi } from "../Apis";
import { useGlobalContext } from "../App/GlobalContext";
import AddIcon from '@mui/icons-material/Add';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Tooltip from '@material-ui/core/Tooltip';
import NewDatasetDialog from "../Datasets/NewDatasetDialog";
import Switch from '@material-ui/core/Switch';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@mui/icons-material/Info';
import {Dataset} from "../interfaces/dataset";

const columns = [
  { id: 'name', label: 'Name', minWidth: 170, style: 'link' },
  { id: 'active', label: 'Active', minWidth: 50, style: 'switch' },
  { id: 'type', label: 'Type', minWidth: 100 },
  { id: 'region', label: 'Region', minWidth: 100 },
  { id: 'role', label: 'Profile', minWidth: 100 },
  { id: 'steward', label: 'Steward', minWidth: 50 },
  { id: 'member', label: 'Member', minWidth: 50 }
];

type SortDir = "asc" | "desc";

const SortDirection = {
  ASC: 'asc',
  DESC: 'desc'
}

type DataRow = {
  name: string;
  active: boolean;
  type: string;
  region: string;
  role: string;
  steward: string;
  member: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  activePopover: {
    padding: theme.spacing(2),
  },
  smallIcon: {
    position: "relative",
    top: theme.spacing(0.5)
  }
}));

interface Group {
  name: string;
  active?: number;
  managed: boolean;
  region: string;
  profile: string;
}

interface User {
  average_entry_size: number;
  member_of?: Group[];
  policy_size: number;
  show_policy_size_limit_notification: boolean;
  steward_on?: Group[];
}

export default function DataSets() {
  const { authSession, logout } = useAuth();
  const { config, setBackdrop, addError, addNotification } = useGlobalContext();
  const queryApi = useQueryApi(config, authSession);
  const commandApi = useCommandApi(config, authSession);
  const [selectedDataset, setSelectedDataset] = React.useState(null as Dataset | null);
  const [openEditDialogState, setOpenEditDialog] = React.useState(false);
  const [user, setUser] = React.useState<User | null>(null);
  const { identity } = useAuth();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState<DataRow[]>([]);
  const [order, setOrder] = React.useState(SortDirection.ASC as SortDir);
  const [orderBy, setOrderBy] = React.useState('name');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorText, setAnchorText] = React.useState('');
  const NotMemberInfoText = "You must be a member of the dataset to set it to active"
  const MaxActiveDatasetsInfoText = "You reached the maximum number of active datasets. Deactivate one in order to activate this one."

  async function initializeData() {
    try {
      const user = await queryApi.getUser(identity!.username);
      setUser(user);
    } catch (err) {
      console.log(err);
    }
  }

  function handleOpenNewDialog(){
    setOpenEditDialog(true)
  }

  function handleCancelNewDatasetDialog() {
    setOpenEditDialog(false)
  }

  async function handleSubmitNewDatasetDialog(dataset: Dataset) {
    try {
      await commandApi.createDataset(dataset);
      setOpenEditDialog(false);
      initializeData();
      setBackdrop(false);
    } catch (err) {
      logout();
    }
  }

  function handleRequestSort(event: any, property: string) {
    const isAsc = orderBy === property && order === SortDirection.ASC;
    setOrder((isAsc ? SortDirection.DESC : SortDirection.ASC) as SortDir);
    setOrderBy(property);
  }

  function descendingComparator(a: any, b: any, orderBy: string) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order: SortDir, orderBy: string) {
    return order === SortDirection.DESC
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array: any[], comparator: any) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  }

  function handleChangePage(event: any, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: any)  {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  async function handleActiveChange(event: any){
    const datasetMember = isMemberOfDataset(event.target.name);
    const toggleActiveDataset = () => commandApi
        .toggleActiveDataset(event.target.name, identity!.username)
        .then(() => initializeData())
        .catch(addError)
        .finally(() => setBackdrop(false));

    if (!datasetMember) {
      setAnchorText(NotMemberInfoText);
      setAnchorEl(event.currentTarget);

      return;
    }

    if (datasetMember.active !== 1) {
      if (!user?.show_policy_size_limit_notification) {
        setBackdrop(true);
        toggleActiveDataset();
      } else {
        setAnchorText(MaxActiveDatasetsInfoText);
        setAnchorEl(event.currentTarget);
      }
    } else {
      setBackdrop(true);
      toggleActiveDataset();
    }
  }

  function isMemberOfDataset(ds_name: string) {
    return user!.member_of!.find(x => x.name === ds_name);
  }

  function handleCloseActivePopover() {
    setAnchorEl(null);
  }

  function EnhancedTableHead(props: any) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: string) => (event: any) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              style={{ minWidth: column.minWidth }}
              sortDirection={orderBy === column.id ? order : false}>
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : 'asc'}
                onClick={createSortHandler(column.id)}>
                {column.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  function renderItemStyle(style: string, value: string, key: string) {
    if (style === 'link') {
      return (
        <a href={'/datasets/' + value}>{value}</a>
      )
    } else if (style === 'switch') {
      return (
        <Tooltip title="Set dataset active or inactive">
          <Switch
            name={key}
            checked={!!value}
            color="primary"
            onChange={handleActiveChange}></Switch>
        </Tooltip>
      )
    }
    return value;
  }

  useEffect(() => {
    initializeData();
  }, [identity, queryApi]);

  useEffect(() => {
    if (user && user.member_of) {
      let members = user.member_of;
      let stewards = user.steward_on ? user.steward_on : [];
      let r: any[] = [];

      members.forEach(x => r.push({
        name: x.name,
        active: !!(x.active && x.active === 1),
        type: x.managed ? 'managed' : 'self',
        region: x.region,
        role: x.profile,
        steward: stewards.find(s => s.name === x.name) ? 'Yes' : 'No',
        member: 'Yes',
      }));
      stewards.filter(x => !members.find(y => y.name === x.name)).forEach(x => r.push({
        name: x.name,
        active: false,
        type: x.managed ? 'managed' : 'self',
        region: x.region,
        role: '',
        steward: 'Yes',
        member: 'No'
      }));
      setRows(r);
    }

    if (user && user.show_policy_size_limit_notification) {
      addNotification(
          "datasets-limit-almost-reached",
          "Active dataset limit almost reached",
          "You have almost reached the maximum dataset permission limit. Please deactivate some datasets in order to access new or other dataset.",
          "");
    }
  }, [user]);

  return (
    <Card>
      <NewDatasetDialog
        openState={openEditDialogState}
        onSubmitForm={handleSubmitNewDatasetDialog}
        onCancel={handleCancelNewDatasetDialog}
      />
      <Popover
        id={"active-popover"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseActivePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.activePopover}>
          <InfoIcon className={classes.smallIcon}></InfoIcon>
          {anchorText}
        </Typography>
      </Popover>
      <CardHeader
        title="Datasets"
        action={
          <Tooltip title="Create new dataset" aria-label="add">
            <Fab color="primary" aria-label="add" onClick={handleOpenNewDialog}>
              <AddIcon />
            </Fab>
          </Tooltip>
        }>
      </CardHeader>
      <CardContent>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.name}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id}>
                            {renderItemStyle(column.style!, value, row.name)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </CardContent>
    </Card>
  );
}
