import React, { useEffect, useContext, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import InputLabel from "@material-ui/core/InputLabel"
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip"
import { useQueryApi, useCommandApi } from "../../Apis";
import { useAuth } from "../../App/AuthContext";
import { useGlobalContext } from "../../App/GlobalContext";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {Dataset} from "../../interfaces/dataset";

const lifeCycleConfigDefault = () => {
    return {
        curated: {
            DaysToExpiration: '',
            NoncurrentDaysToExpiration: '',
            DaysToStandard1A: '',
            DaysToIntelligentTiering: '',
            DaysToOneZone1A: '',
            DaysToGlacier: '',
            DaysToDeepArchive: '',
            NoncurrentDaysToStandard1A: '',
            NoncurrentDaysToIntelligentTiering: '',
            NoncurrentDaysToOneZone1A: '',
            NoncurrentDaysToGlacier: '',
            NoncurrentDaysToDeepArchive: ''
        },
        raw: {
            DaysToExpiration: '',
            NoncurrentDaysToExpiration: '',
            DaysToStandard1A: '',
            DaysToIntelligentTiering: '',
            DaysToOneZone1A: '',
            DaysToGlacier: '',
            DaysToDeepArchive: '',
            NoncurrentDaysToStandard1A: '',
            NoncurrentDaysToIntelligentTiering: '',
            NoncurrentDaysToOneZone1A: '',
            NoncurrentDaysToGlacier: '',
            NoncurrentDaysToDeepArchive: ''
        },
        source: {
            DaysToExpiration: '',
            NoncurrentDaysToExpiration: '',
            DaysToStandard1A: '',
            DaysToIntelligentTiering: '',
            DaysToOneZone1A: '',
            DaysToGlacier: '',
            DaysToDeepArchive: '',
            NoncurrentDaysToStandard1A: '',
            NoncurrentDaysToIntelligentTiering: '',
            NoncurrentDaysToOneZone1A: '',
            NoncurrentDaysToGlacier: '',
            NoncurrentDaysToDeepArchive: ''
        },
        transient: {
            DaysToExpiration: '',
            NoncurrentDaysToExpiration: '',
            DaysToStandard1A: '',
            DaysToIntelligentTiering: '',
            DaysToOneZone1A: '',
            DaysToGlacier: '',
            DaysToDeepArchive: '',
            NoncurrentDaysToStandard1A: '',
            NoncurrentDaysToIntelligentTiering: '',
            NoncurrentDaysToOneZone1A: '',
            NoncurrentDaysToGlacier: '',
            NoncurrentDaysToDeepArchive: ''
        }
    };
}

const removeEmptyFields = (obj: any) => {
    Object.keys(obj).forEach(k =>
        (obj[k] && typeof obj[k] === 'object') && removeEmptyFields(obj[k]) ||
        (!obj[k] && obj[k] !== undefined) && delete obj[k]
    );
    return obj;
};

const deepMerge = (target: any, source: any) => {
    for (const key of Object.keys(source)) {
        if (source[key] instanceof Object) Object.assign(source[key], deepMerge(target[key], source[key]))
    }
    Object.assign(target || {}, source)
    return target
};

const transformToNumber = (obj: any) => {
    Object.keys(obj).forEach(k => {
        if (obj[k] && typeof obj[k] === 'string') {
            obj[k] = parseInt(obj[k]) || ''
        }
    });
}

const LifeCycleContext = React.createContext(lifeCycleConfigDefault())

const useStyles = makeStyles((theme) => ({
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    formControl: {
        margin: theme.spacing(2),
        display: "flex",
        flexDirection: "row"
    },
    textField70: {
        width: 70,
        fontSize: 8
    },
    paper: {
        padding: '6px 16px',
    },
    cardError: {
        border: "2px solid red"
    }
}));

const validateAllTransitionDays = (lifecycle: any, transitions: string[]) => {
    let minDays = 30;
    let invalidTransitions = []
    for (let transition of transitions) {
        const days = parseInt(lifecycle[transition]) || '';
        if (days !== '') {
            if (days < minDays) {
                invalidTransitions.push(transition);
            }
            if (transition === 'DaysToGlacier' || transition == "NoncurrentDaysToGlacier") {
                minDays = days + 90
            } else {
                minDays = days + 30;
            }
        }
    }
    return invalidTransitions;
}

const validateLifecycleDays = (lifecycle: any, isCurrentVersion: boolean) => {
    let invalidTransitions = [];
    if (isCurrentVersion) {
        invalidTransitions = validateAllTransitionDays(lifecycle, ["DaysToStandard1A", "DaysToIntelligentTiering", "DaysToOneZone1A", "DaysToGlacier", "DaysToDeepArchive"])
        console.log("invalid transitions:", invalidTransitions);
    } else {
        invalidTransitions = validateAllTransitionDays(lifecycle, ["NoncurrentDaysToStandard1A", "NoncurrentDaysToIntelligentTiering", "NoncurrentDaysToOneZone1A", "NoncurrentDaysToGlacier", "NoncurrentDaysToDeepArchive"])
        console.log("invalid transitions:", invalidTransitions);
    }

    return invalidTransitions
}

interface TransitionSettingsProps {
    setError: (value: any, zone: string) => void;
    zone: string;
    isCurrentVersion: boolean;
}

function TransitionSettings({ setError, zone, isCurrentVersion }: TransitionSettingsProps) {
    const lifecycle = useContext(LifeCycleContext);
    const idPrefix = isCurrentVersion ? `${zone}-` : `${zone}-Noncurrent`;
    const storagePrefix = isCurrentVersion ? '' : `Noncurrent`;
    const transitionItems = [
        {
            id: `${idPrefix}DaysToStandard1A`,
            title: "S3 Standard-IA",
            storage: `${storagePrefix}DaysToStandard1A`,
            // @ts-ignore
            value: lifecycle[zone][`${storagePrefix}DaysToStandard1A`],
            error: ''
        },
        {
            id: `${idPrefix}DaysToIntelligentTiering`,
            title: "S3 Intelligent tiering",
            storage: `${storagePrefix}DaysToIntelligentTiering`,
            // @ts-ignore
            value: lifecycle[zone][`${storagePrefix}DaysToIntelligentTiering`],
            error: ''
        },
        {
            id: `${idPrefix}DaysToOneZone1A`,
            title: "S3 One Zone-IA",
            storage: `${storagePrefix}DaysToOneZone1A`,
            // @ts-ignore
            value: lifecycle[zone][`${storagePrefix}DaysToOneZone1A`],
            error: ''
        },
        {
            id: `${idPrefix}DaysToGlacier`,
            title: "S3 Glacier",
            storage: `${storagePrefix}DaysToGlacier`,
            // @ts-ignore
            value: lifecycle[zone][`${storagePrefix}DaysToGlacier`],
            error: ''
        },
        {
            id: `${idPrefix}DaysToDeepArchive`,
            title: "S3 Glacier Deep Archive",
            storage: `${storagePrefix}DaysToDeepArchive`,
            // @ts-ignore
            value: lifecycle[zone][`${storagePrefix}DaysToDeepArchive`],
            error: ''
        },
        {
            id: `${idPrefix}DaysToExpiration`,
            title: "Expiration",
            storage: `${storagePrefix}DaysToExpiration`,
            // @ts-ignore
            value: lifecycle[zone][`${storagePrefix}DaysToExpiration`],
            error: ''
        }
    ]

    const [transitions, setTransitions] = useState(transitionItems)

    const validate = (event: any, index: number) => {
        const updatedArray = [...transitions];
        updatedArray[index].value = event.target.value;
        const daysField = updatedArray[index].id.substring(zone.length + 1);
        // @ts-ignore
        lifecycle[zone as keyof typeof lifecycle][daysField] = parseInt(event.target.value) || '';
        const invalidTransitions = validateLifecycleDays(lifecycle[zone as keyof typeof lifecycle], isCurrentVersion);
        for (let item of updatedArray) {
            let found = invalidTransitions.includes(item.storage)
            if (found) {
                item.error = "Invalid days";
            } else {
                item.error = "";
            }
        }
        if (invalidTransitions.length > 0) {
            console.log("call set error");
            // @ts-ignore
            setError(true, zone);
        } else {
            // @ts-ignore
            setError(false, zone);
        }

        setTransitions(updatedArray);
    }

    const classes = useStyles();
    const [daysToTransitionHelper] = React.useState("Enter number of days after object creation that you want the transition to be applied");

    return (
        <>
            <Box flexDirection="row" display="flex">
                <Timeline position="alternate">
                    {transitionItems.map((x, index) => (
                        <React.Fragment key={x.id}>
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Paper elevation={3} className={classes.paper}>
                                        <Typography variant="h6" component="h1">
                                            {x.title}
                                        </Typography>
                                        <Box>
                                            <InputLabel>Days to transition</InputLabel>
                                            <Tooltip title={daysToTransitionHelper}>
                                                <TextField type="number"
                                                    error={transitions[index].error !== "" && transitions[index].error !== undefined}
                                                    helperText={transitions[index].error}
                                                    className={classes.textField70} id={x.id}
                                                    onChange={e => validate(e, index)}
                                                    value={transitions[index].value}
                                                >
                                                </TextField>
                                            </Tooltip>
                                        </Box>
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                        </React.Fragment>
                    ))}

                </Timeline>
            </Box>
        </>
    )
}

interface ZoneConfigurationProps {
    setError: (value: any, zone: string) => void;
    hasError: boolean;
    zone: string;
    title: string;
}

function ZoneConfiguration({ setError, hasError, zone, title }: ZoneConfigurationProps) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card className={hasError?classes.cardError:""}>
            <CardHeader title={title} />
            <CardActions>
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                >
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions>
            <Collapse in={expanded}>
                <CardContent>
                    <Chip label="Current version" color="primary"></Chip>
                    <Divider variant="middle"></Divider>
                    <br />
                    <TransitionSettings setError={setError} zone={zone} isCurrentVersion={true}></TransitionSettings>

                    <br />
                    <Divider variant="middle"></Divider>
                    <Chip label="Previous versions"></Chip>
                    <br />
                    <br />
                    <TransitionSettings setError={setError} zone={zone} isCurrentVersion={false}></TransitionSettings>
                </CardContent>
            </Collapse>
        </Card>
    )
}

interface SaveLifecycleProps {
    onSaveLifecycle: (value: string) => void;
    hasError: boolean;
}

function SaveLifecycle({onSaveLifecycle, hasError}: SaveLifecycleProps) {
    const lifecycleConfig = useContext(LifeCycleContext);
    const { authSession, logout } = useAuth();
    const { config } = useGlobalContext();
    const commandApi = useCommandApi(config, authSession);
    const saveLifecycle = async () => {
        console.log("Saved lifecycle:", lifecycleConfig);
        let transformedData = JSON.parse(JSON.stringify(lifecycleConfig));
        transformedData = removeEmptyFields(transformedData);
        transformToNumber(transformedData["source"]);
        transformToNumber(transformedData["raw"]);
        transformToNumber(transformedData["transient"]);
        transformToNumber(transformedData["curated"]);
        console.log("transformed lifecycle:", transformedData);

        // @ts-ignore
        await onSaveLifecycle(transformedData);
    }

    return (
        <Grid container alignItems="flex-start" justify="flex-end" direction="row">
            <Button
                color="primary"
                onClick={saveLifecycle}
                disabled={hasError}>
                Save
            </Button>
        </Grid>
    )
}

function DatasetLifecycle({ dataset }: {dataset: Dataset}) {
    console.log("dataset: ", dataset);

    let lifecycle = useContext(LifeCycleContext);

    const [lifecycleLoaded, setLifecycleLoaded] = useState(false);
    const [hasError, setHaserror] = useState(false);
    const [zoneErrors, setZoneErrors] = useState({curated:false,source:false,raw:false,transient:false})

    const { authSession, logout } = useAuth();
    const { config } = useGlobalContext();
    const queryApi = useQueryApi(config, authSession);
    const commandApi = useCommandApi(config, authSession);

    const setError = (value: any, zone: string) => {
        setHaserror(value);
        const newZoneErrorState = {...zoneErrors}
        // @ts-ignore
        newZoneErrorState[zone] = value;
        console.log("new zone errors:",newZoneErrorState);
        setZoneErrors(newZoneErrorState);
    }

    const onSaveLifecycle = async (value: string) => {
        try {
            await commandApi.updateLifecycle(dataset.name, value);
            console.log("saved lifecycle");
        } catch (e) {
            console.log(e);
            logout();
        }
    }

    useEffect(() => {
        async function initializeData() {
            try {
                const data = await queryApi.getLifecycleConfiguration(dataset['name']);
                console.log("fetched lifecycle:", data);
                if (data.lifecycle) {
                    const loadedLifecycle = deepMerge(lifecycle, data.lifecycle);
                    console.log("merged lifecycle: ", loadedLifecycle);
                    lifecycle = loadedLifecycle;
                }

            } catch (err) {
                console.log(err);
            }
            finally {
                setLifecycleLoaded(true)
            }
        }
        initializeData();
    }, [dataset]);

    const zones = ["source", "raw", "transient", "curated"]
    if (lifecycleLoaded) {
        return (
            <>
                <LifeCycleContext.Provider value={lifecycle}>
                    {zones.map(x => (
                        <React.Fragment key={x}>
                            <ZoneConfiguration setError={setError} hasError={zoneErrors[x as keyof typeof zoneErrors]} zone={x} title={x}></ZoneConfiguration>
                            <br />
                        </React.Fragment>
                    ))};
                    <SaveLifecycle hasError={hasError} onSaveLifecycle={onSaveLifecycle}></SaveLifecycle>
                </LifeCycleContext.Provider>
            </>
        )
    } else {
        return (<div></div>)
    }
};

export default DatasetLifecycle;