import React from 'react';
import MBackdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import {useGlobalContext} from "./GlobalContext";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 10000,
        color: '#fff',
    },
}));

export default function Backdrop() {
    const classes = useStyles();
    const globalContext = useGlobalContext();

    return (
        <div>
            <MBackdrop className={classes.backdrop} open={globalContext.backdrop}>
                <CircularProgress color="inherit" />
            </MBackdrop>
        </div>
    );
}