import React from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

interface SubsetMenuButtonProps {
    subsetName: string;
    onDeleteSubset: (subsetName: string) => void;
}

export default function SubsetMenuButton({subsetName, onDeleteSubset}: SubsetMenuButtonProps) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        setAnchorEl(null);
        onDeleteSubset(subsetName);
    };

    return (
        <div>
            <Button aria-controls={`subset-action-menu`} aria-haspopup="true" onClick={handleClick}>
                <MoreHorizIcon /> 
            </Button>
            <Menu
                id={`subset-action-menu`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
        </div>
    );
  }

