import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    }
}));

interface ProfileDialogProps {
    id: string;
    isOpen: boolean;
    handleClosed: (profile: any) => void;
    handleCancel: () => void;
    message: string;
    title: string;
    profiles: any[];
}

export default function ProfileDialog({
    id,
    isOpen,
    handleClosed,
    handleCancel,
    message,
    title,
    profiles
}: ProfileDialogProps) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(isOpen);
    const [profile, setProfile] = React.useState("reader");

    const onClose = () => {
        handleClosed(profile);
    };

    const onCancel = () => {
        handleCancel();
    };

    const handleChangeProfile = (event: any) => {
        setProfile(event.target.value);
    }

    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen]);

    return (
        <div>
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                onClose={onClose}
                aria-labelledby={id}
                aria-describedby={id + "-description"}
            >
                <DialogTitle id={id}>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id={id + "-description"}>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <FormControl className={classes.formControl}>
                    <InputLabel id={id+"-select-label"}>Profile</InputLabel>
                    <Select
                        value={profile}
                        labelId={id+"-select-label"}
                        onChange={(event) => handleChangeProfile(event)}>
                        {profiles.map(x => (
                            <MenuItem key={x.name} value={x.name}>{x.name}</MenuItem>
                        ))};
                    </Select>
                </FormControl>
                <DialogActions>
                    <Button onClick={onClose} color="primary" autoFocus>
                        Ok
                    </Button>
                    <Button onClick={onCancel} color="primary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
