import React from "react";
import {useGlobalContext} from "../GlobalContext";
import MaterialTable from "material-table";
import Switch from "@material-ui/core/Switch";

export function FeatureSwitches() {
    const {config, toggleFeature} = useGlobalContext();
    const [columns] = React.useState([{title: 'Feature', field: 'name'}])

    async function handleToggleFeature(rowData: { name: string }) {
        toggleFeature(rowData.name)
    }

    return (
        <React.Fragment>
            <MaterialTable
                title="Feature switches"
                columns={columns}
                data={config.featureSwitches}
                options={{
                    actionsColumnIndex: -1,
                    grouping: false,
                }}
                actions={[
                    {
                        icon: 'save',
                        tooltip: 'Save User',
                        onClick: (event: any, rowData: any) => handleToggleFeature(rowData)
                    }
                ]}
                components={{
                    Action: props => (
                        <React.Fragment>
                            <Switch
                                checked={props.data.enabled}
                                onChange={(event) => props.action.onClick(event, props.data)}
                                name="toggleFeature"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                color="primary"
                            />
                        </React.Fragment>
                    )}}
            />
        </React.Fragment>)
}
