import React from "react";
import GroupTransferList from "./GroupTransferList";

function DatasetGroups({groups, members, onAddGroup, onRemoveGroup, onChangeProfile, profiles}) {

    return (
        <React.Fragment>
            <GroupTransferList
                columns={[
                    {title: 'Group', field: 'name', defaultSort: 'asc'},
                    {title: 'Profile', field: 'profile', hidden: false}
                ]}
                leftHeader="All groups"
                leftItems={groups}
                rightHeader="Groups"
                rightItems={members}

                onLeftChanged={({item: group, profile}) => {
                    onAddGroup(group, profile);
                }}
                onRightChanged={({item: member}) => {
                    onRemoveGroup(member)
                }}
                editable={false}
                editable={true}
                onChangeProfile={onChangeProfile}
                profiles={profiles}
            />
        </React.Fragment>
    );
};

export default DatasetGroups;