import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

import { Hub, Auth } from 'aws-amplify';
import { useGlobalContext } from './GlobalContext';
import { QueryFactory } from '../Apis/Query';

const authStateKey = 'authState';

export interface Identity {
    idToken: string;
    accessToken: string;
    username: string;
    steward: string;
}

interface InitialState {
    authState: any;
    signInText: string;
    identity?: Identity | null;
    graphToken: string;
    authSession: any;
    login: () => void;
    logout: () => void;
}

const initialState: InitialState = {
    authState: null,
    signInText: 'Please sign in',
    identity: null,
    graphToken: '',
    authSession: null,
    login: () => {},
    logout: () => {},
};

export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
    const { config, addError } = useGlobalContext();
    const [authState, setAuthState] = useState(initialState.authState);
    const [authSession, setAuthSession] = useState(initialState.authSession);
    const [signInText, setSignInText] = useState(initialState.signInText);
    const [identity, setIdentity] = useState(initialState.identity);
    const [graphToken, setGraphToken] = useState(initialState.graphToken);

    const logout = useCallback(async () => {
        localStorage.clear();
        await Auth.signOut();
    }, []);

    const login = useCallback(async () => {
        // @ts-ignore
        await Auth.federatedSignIn({ provider: 'CognitoAADProvider' });
    }, []);

    const getGraphToken = useCallback(
        async (session, config) => {
            try {
                if (graphToken === '') {
                    return await QueryFactory({
                        config,
                        authSession: session,
                    }).getGraphToken();
                }
            } catch (err) {
                await logout();
            }
        },
        [logout, config, graphToken],
    );

    const getAndSetUserIdentity = useCallback(
        async (session, config) => {
            try {
                if (identity === null) {
                    const username =
                        session.getIdToken().payload['identities'][0]['userId'];
                    const userData = await QueryFactory({
                        config,
                        authSession: session,
                    }).getUser(username);
                    return {
                        idToken: session.getIdToken().getJwtToken(),
                        accessToken: session.getAccessToken().getJwtToken(),
                        username: username,
                        steward: userData.steward,
                    };
                }
            } catch (err) {
                await logout();
            }
        },
        [logout, identity],
    );

    const handleSignedInEvent = useCallback(async () => {
        setSignInText('Please wait, authorizing...');
        setAuthState('signingIn');
        try {
            const session = await Auth.currentSession();
            console.log('Init AuthSession', session);
            setAuthSession(session);
            const i = await getAndSetUserIdentity(session, config);
            const g = await getGraphToken(session, config);
            setIdentity(i);
            setGraphToken(g);
            const a = {
                state: 'signedIn',
                identity: i,
                graphToken: g,
                session: session,
            };
            localStorage.setItem(authStateKey, JSON.stringify(a));
            console.debug('signedIn');
            setSignInText('');
            setAuthState('signedIn');
        } catch (e) {
            await logout();
        }
    }, [getAndSetUserIdentity, getGraphToken, logout, config]);

    const signedOutEventHandler = useCallback(async () => {
        setSignInText('Please wait, signing out...');
        localStorage.removeItem(authStateKey);
        setGraphToken(initialState.graphToken);
        setAuthState(initialState.authState);
        setAuthSession(initialState.authSession);
        setIdentity(initialState.identity);
        setSignInText(initialState.signInText);
    }, []);

    useEffect(() => {
        Hub.listen('auth', ({ payload }) => {
            console.log('Hub received');
            const { event } = payload;
            switch (event) {
                case 'signIn':
                    console.log('Hub signIn event');
                    handleSignedInEvent().catch((err) => {
                        addError(err);
                        console.error(err);
                    });
                    break;
                case 'signOut':
                    console.log('Hub signOut event');
                    signedOutEventHandler().catch((err) => {
                        addError(err);
                        console.error(err);
                    });
                    break;
                default:
                    console.debug('Unused Hub event', event);
            }
        });
    }, [addError, handleSignedInEvent, signedOutEventHandler]);

    useEffect(() => {
        let a: any | null = null;
        try {
            a = JSON.parse(localStorage.getItem(authStateKey) || '');
        } catch {}
        if (a !== null && a.state === 'signedIn') {
            console.debug('signedIn');
            setIdentity(a.identity);
            setGraphToken(a.graphToken);
            setSignInText('');
            Auth.currentSession()
                .then((session) => {
                    setAuthSession(session);
                    setAuthState(a.state);
                })
                .catch((err) => {
                    logout();
                });
        }
    }, []);

    return (
        <AuthContext.Provider
            value={{
                authState,
                authSession,
                identity,
                graphToken,
                signInText,
                logout,
                login,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
