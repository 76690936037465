import React from "react";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import MLink from "@material-ui/core/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import Tooltip from "@material-ui/core/Tooltip"

import Users from "../../Users";
import CredentialsPage from "../../Credentials";
import Home from "../../Home";
import Login from "./Login";
import {FeatureSwitches} from "./FeatureSwitches";
import {BrowserRouter as Router, Route, Link, Routes} from "react-router-dom";
import {Notifications} from "./Notifications";
import UserProfile from "./UserProfile";
import {useAuth} from "../AuthContext";

import Datasets from "../../Datasets";
import Dataset from "../../Datasets/Dataset"
import {useFeatureSwitch} from "../GlobalContext";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: "none",
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 240,
    },
    menuLinks: {
        textDecoration: "none",
        color: "black",
    },
}));


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <MLink color="inherit" href="https://novonordisk.com/">
                Novo Nordisk A/S
            </MLink>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

interface LayoutAppBarProps {
    signInText: string;
    open: boolean;
    onMenuClick: () => void;
}

function LayoutAppBar({signInText, open, onMenuClick}: LayoutAppBarProps) {
    const classes = useStyles();
    return (
        <AppBar
            position="absolute"
            className={clsx(classes.appBar, open && classes.appBarShift)}
        >
            <Toolbar className={classes.toolbar}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={onMenuClick}
                    className={clsx(
                        classes.menuButton,
                        open && classes.menuButtonHidden
                    )}
                >
                    <MenuIcon/>
                </IconButton>
                <Typography
                    component="h1"
                    color="inherit"
                    noWrap
                    className={classes.title}
                >
                    NNEDL Administration
                </Typography>

                <Typography color="inherit" >
                    {signInText}
                </Typography>
                <Notifications />
                <UserProfile />
            </Toolbar>
        </AppBar>
    );
}

interface LayoutDrawerProps {
    open: boolean;
    onMenuClick: () => void;
}

function LayoutDrawer({open, onMenuClick}: LayoutDrawerProps) {
    const classes = useStyles();
    const userManagement = useFeatureSwitch("UserManagement");
    console.log("userManagement", userManagement)
    return (
        <Drawer
            variant="permanent"
            classes={{
                paper: clsx(
                    classes.drawerPaper,
                    !open && classes.drawerPaperClose
                ),
            }}
            open={open}
        >
            <div className={classes.toolbarIcon}>
                <IconButton onClick={onMenuClick}>
                    <ChevronLeftIcon/>
                </IconButton>
            </div>
            <Divider/>
            <List>
                <div>
                    <Link to="/" className={clsx(classes.menuLinks)}>
                        <Tooltip title="home">
                            <ListItem button>
                                <ListItemIcon>
                                    <DashboardIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Overview"/>
                            </ListItem>
                        </Tooltip>
                    </Link>

                    <Link to="/credentials" className={clsx(classes.menuLinks)}>
                        <Tooltip title="credentials">
                            <ListItem button>
                                <ListItemIcon>
                                    <VpnKeyIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Credentials"/>
                            </ListItem>
                        </Tooltip>
                    </Link>
                    { userManagement ?
                        <Link to="/users" className={clsx(classes.menuLinks)}>
                            <Tooltip title="users">
                                <ListItem button>
                                    <ListItemIcon>
                                        <PermIdentityIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Users"/>
                                </ListItem>
                            </Tooltip>
                        </Link>
                    : "" }
                </div>
            </List>
        </Drawer>
    );
}

function LayoutMain() {
    const classes = useStyles();

    return (
        <main className={classes.content}>
            <div className={classes.appBarSpacer}/>
            <Container maxWidth="lg" className={classes.container}>
                <Routes>
                    <Route
                        path="/credentials"
                        element={<CredentialsPage />}
                    />
                    <Route path="/" element={<Home />} />
                    <Route path="/datasets" element={<Datasets />} />
                    <Route path="/datasets/:name" element={<Dataset />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="/features" element={<FeatureSwitches />} />
                </Routes>
                <Box pt={4}>
                    <Copyright/>
                </Box>
            </Container>
        </main>
    );
}

export default function Layout() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const {authState, signInText} = useAuth()

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    }

    return (
        <Router>
            <div className={classes.root}>
                <CssBaseline/>
                <LayoutAppBar signInText={signInText} open={open} onMenuClick={handleDrawerOpen}/>
                {(authState === "signedIn") ? (
                    <>
                        <LayoutDrawer open={open} onMenuClick={handleDrawerClose} />
                        <LayoutMain />
                    </>
                ) : (
                    <Login/>
                )}
            </div>
        </Router>
    );
}
